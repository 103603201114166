package techla.guard

import kotlinx.serialization.Serializable
import techla.base.*

@Serializable
data class ApplicationAuthentication(
    val index: Index<ApplicationAuthentication>,
    val device: Device,
    val createdAt: Date,
    val application: Application?,
    val tokens: List<Token>,

    @Deprecated("Will be removed in the future")
    val claims: String,
    @Deprecated("Replaced by token list")
    val token: String? = null,
) {
    @Serializable
    data class Create(
        val applicationKey: String,
        val applicationSecret: String,
        val device: Device = Device.Other,
    )
}