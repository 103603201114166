package techla.guard

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import techla.base.*

@Serializable
data class Profile(
    val index: Index<Profile>,

    val govId: String?,
    val firstName: String?,
    val lastName: String?,
    val email: String?,
    val phone: String?,

    val source: Source,

    val access: List<Access>,
    val workspaces: List<Key<Workspace>>,

    val inactivatedAt: Date?,

    val createdAt: Date,
    val editedAt: Date,
) : BaseProfile {
    val services: List<Key<Service>>
        get() = access.map { it.service }

    @Serializable(with = ProfileSourceSerializer::class)
    sealed class Source {
        object Unknown : Source()
        object Automatic : Source()
        object Manual : Source()
        object Import : Source()

        val rawValue: String
            get() = ProfileSourceSerializer.serialize(this).first()

        companion object {}

        override fun toString(): String {
            return when (this) {
                is Unknown -> "Source.Unknown"
                is Automatic -> "Source.Automatic"
                is Manual -> "Source.Manual"
                is Import -> "Source.Import"
            }
        }
    }

    @Serializable
    data class Create(
        val govId: String? = null,
        val firstName: String? = null,
        val lastName: String? = null,
        val email: String? = null,
        val phone: String? = null,

        val source: Source = Source.Manual,

        val access: List<Access>? = null,
        val workspaces: List<Key<Workspace>>? = null
    )

    @Serializable
    data class Edit(
        val govId: Modification<String?> = Modification.Unmodified,
        val firstName: Modification<String?> = Modification.Unmodified,
        val lastName: Modification<String?> = Modification.Unmodified,
        val email: Modification<String?> = Modification.Unmodified,
        val phone: Modification<String?> = Modification.Unmodified,

        val source: Modification<Source> = Modification.Unmodified,

        val access: Modification<List<Access>> = Modification.Unmodified,
        val workspaces: Modification<List<Key<Workspace>>> = Modification.Unmodified,

        val inactivatedAt: Modification<Date?> = Modification.Unmodified
    )

    @Serializable
    data class MeEdit(
        val email: Modification<String?> = Modification.Unmodified,
        val phone: Modification<String?> = Modification.Unmodified,
    )
}

object ProfileSourceSerializer : KSerializer<Profile.Source> {
    override val descriptor: SerialDescriptor = ListStringSerializer.descriptor

    override fun serialize(encoder: Encoder, value: Profile.Source) = encoder.encodeSerializableValue(ListStringSerializer, serialize(value))

    override fun deserialize(decoder: Decoder): Profile.Source = deserialize(decoder.decodeSerializableValue(ListStringSerializer))

    private const val AUTOMATIC = "automatic"
    private const val MANUAL = "manual"
    private const val IMPORT = "import"

    fun serialize(obj: Profile.Source): List<String> {
        return when (obj) {
            is Profile.Source.Unknown -> throw TechlaError.PreconditionFailed("Can't encode Profile.Source.Unknown")
            is Profile.Source.Automatic -> listOf(AUTOMATIC)
            is Profile.Source.Manual -> listOf(MANUAL)
            is Profile.Source.Import -> listOf(IMPORT)
        }
    }

    fun deserialize(data: List<String>): Profile.Source {
        return when (data.first()) {
            AUTOMATIC -> Profile.Source.Automatic
            MANUAL -> Profile.Source.Manual
            IMPORT -> Profile.Source.Import
            else -> Profile.Source.Unknown
        }
    }
}
