package components

import emotion.react.css
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import support.DesignSystem
import support.HetchColors
import web.cssom.*
import web.html.ButtonType

external interface HDialogProps : PropsWithChildren {
    var open: Boolean
    var title: String
    var onClose: () -> Unit
}

val HDialog = FC<HDialogProps> { props ->
    Dialog {
        open = props.open
        onClose = { _, _ -> props.onClose() }
        fullWidth = true
        maxWidth = "sm"

        DialogContent {
            sx {
                backgroundColor = important(Color(HetchColors.black))
            }

            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    justifyContent = JustifyContent.flexEnd
                    borderBottom = Border(1.px, LineStyle.solid)
                    borderColor = Color(HetchColors.white)
                    padding = 16.px
                }

                HButton {
                    design = DesignSystem.Button(type = ButtonType.button, text = "Close")
                    onClick = { _ -> props.onClose() }
                }
            }

            Typography {
                variant = TypographyVariant.h3
                align = TypographyAlign.center
                sx {
                    paddingBottom = 16.px
                    marginTop = 96.px
                    textTransform = TextTransform.uppercase
                }

                +props.title
            }

            Stack {
                spacing = responsive(2)

                sx {
                    padding = 16.px
                    backgroundColor = Color(HetchColors.gray)
                    marginBottom = 96.px
                }

                +props.children
            }

            div {
                css {
                    borderTop = Border(1.px, LineStyle.solid)
                    borderColor = Color(HetchColors.white)
                    padding = 16.px
                }
            }
        }
    }

}