import components.Theme
import js.core.jso
import kotlinx.browser.localStorage
import kotlinx.browser.window
import react.*
import react.router.RouterProvider
import react.router.dom.createBrowserRouter
import support.Store
import support.deployment
import techla.base.Device
import techla.base.Identifier
import views.Book
import views.Code
import views.Enter
import views.Users


val storeReducer: Reducer<Store, List<Store.Action>> = { store, actions ->
    actions.filterIsInstance<Store.Action.UserAuthenticationStarted>().firstOrNull()?.let {
        localStorage.setItem("userId", it.userAuthenticationId.rawValue)
    }

    store.reduce(actions)
}


val emptyStore = Store(applicationContext = null, deployment = deployment, device = Device.Web(window.navigator.userAgent))

var StoreContext = createContext<Pair<Store, Dispatch<List<Store.Action>>>>(
    emptyStore.copy(userAuthenticationId = localStorage.getItem("userId")?.let { Identifier(it) })
            to
            { _ -> emptyList<Store.Action>() }
)


val App = FC<Props> {
    val (store, storeDispatch) = useReducer(
        storeReducer, initialState = Store(
            deployment = deployment,
            device = Device.Web(window.navigator.userAgent),
            applicationContext = null,
            applicationToken = null,
        )
    )
    StrictMode {
        StoreContext.Provider(store to storeDispatch) {
            Theme {
                RouterProvider {
                    router = createBrowserRouter(
                        arrayOf(
                            jso {
                                path = "/"
                                children = arrayOf(
                                    jso { path = "/"; index = true; element = Enter.create() },
                                    jso { path = "/zone"; element = Enter.create() },
                                    jso { path = "/code"; element = Code.create() },
                                    jso { path = "/book"; element = Book.create() },
                                    jso { path = "/users"; element = Users.create() },
                                )
                            }
                        )
                    )
                }
            }
        }
    }
}