package support

import io.ktor.client.*
import techla.base.Date
import techla.base.Device
import techla.base.Identifier
import techla.guard.Me
import techla.guard.Profile
import techla.guard.Token
import techla.guard.UserAuthentication
import techla.reservation.Reservation
import techla.reservation.Resource

typealias ApplicationContext = Any?

data class Deployment(
    val applicationKey: String,
    val applicationSecret: String,
    val redirect: String,
    val isSandbox: Boolean,
    val version: String,
    val build: Int,
)

data class Store(
    val applicationContext: ApplicationContext,
    val deployment: Deployment,
    val device: Device,

    val applicationToken: String? = null,
    val userAuthenticationId: Identifier<UserAuthentication>? = null,
    val tokens: List<Token> = emptyList(),
    val profileId: Identifier<Profile>? = null,
    val me: Me? = null,

    val rooms: List<Resource> = emptyList(),
    val reservations: List<Reservation> = emptyList(),

    val profiles: List<Profile> = emptyList(),

    val capacity: Int? = null,
    val floor: Int? = null,
    val date: Date = Date.now(),
) {
    sealed class Action {
        data class ApplicationAuthenticationCompleted(val applicationToken: String) : Action()
        data class UserAuthenticationStarted(val userAuthenticationId: Identifier<UserAuthentication>) : Action()
        data class UserAuthenticationCompleted(val tokens: List<Token>, val profileId: Identifier<Profile>?) : Action()
        data class TokenRefresh(val tokens: List<Token>) : Action()

        data class GetMe(val me: Me) : Action()
        data class ListProfiles(val profiles: List<Profile>) : Action()

        data class ListRooms(val rooms: List<Resource>) : Action()
        data class ListReservations(val reservations: List<Reservation>) : Action()
        data class FilterRooms(val rooms: List<Resource>, val capacity: Int?, val floor: Int?) : Action()
        data class ChangeDate(val date: Date) : Action()
    }

    val userToken: String?
        get() = tokens.filterIsInstance<Token.User>().firstOrNull()?.token

    val adminToken: String?
        get() = tokens.filterIsInstance<Token.Admin>().firstOrNull()?.token

    val tokenExpiresAt: Date?
        get() = tokens.filterIsInstance<Token.User>().firstOrNull()?.expiresAt

    val isSignedIn: Boolean
        get() = userToken != null

    val isAdmin: Boolean
        get() = adminToken != null

    val myReservations: List<Reservation>
        get() = reservations.filter { it.profileId == profileId }

    val version: String
        get() =
            if (deployment.isSandbox)
                "${deployment.version.replace("-SNAPSHOT", "")} (${deployment.build})"
            else
                deployment.version.replace("-SNAPSHOT", "")

    fun reduce(action: Action) = when (action) {
        is Action.ApplicationAuthenticationCompleted -> copy(applicationToken = action.applicationToken)
        is Action.UserAuthenticationStarted -> copy(userAuthenticationId = action.userAuthenticationId)
        is Action.UserAuthenticationCompleted -> copy(tokens = action.tokens, profileId = action.profileId)
        is Action.TokenRefresh -> copy(tokens = action.tokens)
        is Action.GetMe -> copy(me = action.me)
        is Action.ListProfiles -> copy(profiles = action.profiles)
        is Action.ListRooms -> copy(rooms = action.rooms)
        is Action.ListReservations -> copy(reservations = action.reservations)
        is Action.FilterRooms -> copy(rooms = action.rooms, floor = action.floor, capacity = action.capacity)
        is Action.ChangeDate -> copy(date = action.date)
    }

    fun reduce(actions: List<Action>) = actions.fold(this) { store, action -> store.reduce(action) }


    val httpClient = HttpClient() {
        expectSuccess = false
    }
}
