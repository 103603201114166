package techla.base

import kotlinx.serialization.*

expect fun uniqueIndexValue() : String

@Serializable
data class Index<Tag>(
    @SerialName("id")
    val rawValue: String = uniqueIndexValue(),
    var link: Link? = null
) {
    val id : Identifier<Tag>
        get() = Identifier(rawValue)

    inline fun <Target> map(f: (String) -> String): Index<Target> {
        val index = Index<Target>(rawValue = f(rawValue))
        index.link = link
        return index
    }
}
