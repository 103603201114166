package components

import mui.material.Button
import mui.material.ButtonColor
import mui.material.ButtonVariant
import web.dom.Element
import react.FC
import react.PropsWithChildren
import react.dom.events.MouseEvent
import support.DesignSystem

external interface HButtonProps : PropsWithChildren {
    var design: DesignSystem.Button
    var onClick: ((MouseEvent<Element, *>) -> Unit)?
}

val HButton = FC<HButtonProps> { props ->
    Button {
        color = if (props.design.danger) ButtonColor.secondary else ButtonColor.primary

        type = props.design.type
        onClick = props.onClick ?: {}
        disabled = props.design.disabled
        variant = ButtonVariant.contained

        +props.children
        +props.design.text
    }
}