package techla.reservation

import kotlinx.serialization.*
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.encoding.CompositeDecoder
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.*
import techla.base.*
import techla.guard.Profile

@Serializable
data class Reservation(
    val id: Identifier<Reservation>,
    val profileId: Identifier<Profile>,
    val name: String,
    val startsAt: Date,
    val endsAt: Date,
    val style: Style,
    val value: Value,
    val resource: Resource,
) {
    companion object {
        const val WARNING_REVERSED = "reversed" // Reservation start must be earlier than it's end
        const val WARNING_PASSED = "passed" // Reservation start has already passed
        const val WARNING_RESTRICTED = "restricted" // Resource is restricted
        const val WARNING_OVERLAPS = "overlaps" // Reservation overlaps another
        const val WARNING_CONCURRENT_SHORT_HIGH = "warning_concurrent_short_high" // Number of concurrent short style, high value reservations exceeded
        const val WARNING_CONCURRENT_LONG_HIGH = "warning_concurrent_long_high" // Number of concurrent long style, high value reservations exceeded
        const val WARNING_CONCURRENT_SHORT_NORMAL = "warning_concurrent_short_normal" // Number of concurrent short style, normal value reservations exceeded
        const val WARNING_CONCURRENT_LONG_NORMAL = "warning_concurrent_long_normal" // Number of concurrent long style, normal value reservations exceeded
        const val WARNING_DURATION_SHORT = "warning_duration_short" // Duration for short style exceeded
        const val WARNING_DURATION_LONG = "warning_duration_long" // Duration for long style exceeded
    }
    @Serializable(with = ReservationStyleSerializer::class)
    sealed class Style(private val _discriminator: String) {
        object Unknown : Style(ReservationStyleSerializer.UNKNOWN)

        object None : Style(ReservationStyleSerializer.NONE)
        object Long : Style(ReservationStyleSerializer.LONG)
        object Short : Style(ReservationStyleSerializer.SHORT)

        val rawValue: String get() = _discriminator

        companion object;
        override fun toString() =
            when (this) {
                is Unknown -> "Reservation.Style.Unknown"
                is None -> "Reservation.Style.None"
                is Long -> "Reservation.Style.Long"
                is Short -> "Reservation.Style.Short"
            }
    }

    @Serializable(with = ReservationValueSerializer::class)
    sealed class Value(private val _discriminator: String) {
        object Unknown : Value(ReservationValueSerializer.UNKNOWN)

        object None : Value(ReservationValueSerializer.NONE)
        object Normal : Value(ReservationValueSerializer.NORMAL)
        object High : Value(ReservationValueSerializer.HIGH)

        val rawValue: String get() = _discriminator

        companion object;
        override fun toString() =
            when (this) {
                is Unknown -> "Reservation.Value.Unknown"
                is None -> "Reservation.Value.None"
                is Normal -> "Reservation.Value.Normal"
                is High -> "Reservation.Value.High"
            }
    }

    @Serializable
    data class Create(
        val resource: Key<Resource>,
        val name: String,
        val startsAt: Date,
        val endsAt: Date,
        val style: Style = Style.None,
        val value: Value = Value.None,
    )

    @Serializable
    data class Edit(
        val name: Modification<String> = Modification.Unmodified,
    )

    @Serializable(with = ReservationFilterSerializer::class)
    sealed class Filter {
        object Future : Filter()
        data class FutureYearOffset(val offset: Int) : Filter()

        data class ForDate(val date: Date) : Filter()
        data class ForMonth(val date: Date) : Filter()
        data class ForMonthOffset(val offset: Int) : Filter()
        data class ForYearOffset(val offset: Int) : Filter()

        override fun toString(): String {
            return when (this) {
                is Future -> "Filter.Future"
                is FutureYearOffset -> "Filter.FutureYearOffset($offset)"
                is ForDate -> "Filter.ForDate($date)"
                is ForMonth -> "Filter.ForMonth($date)"
                is ForMonthOffset -> "Filter.ForMonthOffset($offset)"
                is ForYearOffset -> "Filter.ForYearOffset($offset)"
            }
        }
    }
}

data class ReservationStyleComponents(
    val rawValue: String,
)

fun Reservation.Style.flatten() =
    when (this) {
        is Reservation.Style.None -> ReservationStyleComponents(rawValue)
        is Reservation.Style.Long -> ReservationStyleComponents(rawValue)
        is Reservation.Style.Short -> ReservationStyleComponents(rawValue)
        is Reservation.Style.Unknown ->
            throw TechlaError.PreconditionFailed("Reservation.Style.Unknown cannot be flatten")
    }

fun Reservation.Style.Companion.unflatten(components: ReservationStyleComponents) =
    when (components.rawValue) {
        ReservationStyleSerializer.NONE -> Reservation.Style.None
        ReservationStyleSerializer.LONG -> Reservation.Style.Long
        ReservationStyleSerializer.SHORT -> Reservation.Style.Short
        else -> Reservation.Style.Unknown
    }

object ReservationStyleSerializer : KSerializer<Reservation.Style> {
    const val UNKNOWN = "unknown"
    const val NONE = "none"
    const val LONG = "long"
    const val SHORT = "short"

    override val descriptor: SerialDescriptor =
        String.serializer().descriptor

    override fun serialize(encoder: Encoder, value: Reservation.Style) {
        require(encoder is JsonEncoder)

        val jsonObject = buildJsonObject {
            put("_discriminator", value.rawValue)

            when (value) {
                is Reservation.Style.None -> {
                }
                is Reservation.Style.Long -> {
                }
                is Reservation.Style.Short -> {
                }
                is Reservation.Style.Unknown ->
                    throw TechlaError.PreconditionFailed("Reservation.Style.Unknown cannot be serialized")
            }
        }

        encoder.encodeJsonElement(jsonObject)
    }

    override fun deserialize(decoder: Decoder): Reservation.Style {
        require(decoder is JsonDecoder)
        val jsonObject = decoder.decodeJsonElement().jsonObject

        return when (jsonObject["_discriminator"]?.jsonPrimitive?.content) {
            NONE -> Reservation.Style.None
            LONG -> Reservation.Style.Long
            SHORT -> Reservation.Style.Short
            else -> Reservation.Style.Unknown
        }
    }
}

data class ReservationValueComponents(
    val rawValue: String,
)

fun Reservation.Value.flatten() =
    when (this) {
        is Reservation.Value.None -> ReservationValueComponents(rawValue)
        is Reservation.Value.Normal -> ReservationValueComponents(rawValue)
        is Reservation.Value.High -> ReservationValueComponents(rawValue)
        is Reservation.Value.Unknown ->
            throw TechlaError.PreconditionFailed("Reservation.Value.Unknown cannot be flatten")
    }

fun Reservation.Value.Companion.unflatten(components: ReservationValueComponents) =
    when (components.rawValue) {
        ReservationValueSerializer.NONE -> Reservation.Value.None
        ReservationValueSerializer.NORMAL -> Reservation.Value.Normal
        ReservationValueSerializer.HIGH -> Reservation.Value.High
        else -> Reservation.Value.Unknown
    }

object ReservationValueSerializer : KSerializer<Reservation.Value> {
    const val UNKNOWN = "unknown"
    const val NONE = "none"
    const val NORMAL = "normal"
    const val HIGH = "high"

    override val descriptor: SerialDescriptor =
        String.serializer().descriptor

    override fun serialize(encoder: Encoder, value: Reservation.Value) {
        require(encoder is JsonEncoder)

        val jsonObject = buildJsonObject {
            put("_discriminator", value.rawValue)

            when (value) {
                is Reservation.Value.None -> {
                }
                is Reservation.Value.Normal -> {
                }
                is Reservation.Value.High -> {
                }
                is Reservation.Value.Unknown ->
                    throw TechlaError.PreconditionFailed("Reservation.Value.Unknown cannot be serialized")
            }
        }

        encoder.encodeJsonElement(jsonObject)
    }

    override fun deserialize(decoder: Decoder): Reservation.Value {
        require(decoder is JsonDecoder)
        val jsonObject = decoder.decodeJsonElement().jsonObject

        return when (jsonObject["_discriminator"]?.jsonPrimitive?.content) {
            NONE -> Reservation.Value.None
            NORMAL -> Reservation.Value.Normal
            HIGH -> Reservation.Value.High
            else -> Reservation.Value.Unknown
        }
    }
}

object ReservationFilterSerializer : KSerializer<Reservation.Filter> {
    override val descriptor: SerialDescriptor
        = buildClassSerialDescriptor("ReservationFilterSerializer") {
            element("kind", String.serializer().descriptor) // kind will have index 0
            element("data", String.serializer().descriptor) // data will have index 1
        }

    override fun serialize(encoder: Encoder, value: Reservation.Filter) {
        val pair = serialize(value)
        val composite = encoder.beginStructure(descriptor)
        composite.encodeStringElement(descriptor, 0, pair.first)
        composite.encodeStringElement(descriptor, 1, pair.second)
        composite.endStructure(descriptor)
    }

    override fun deserialize(decoder: Decoder): Reservation.Filter {
        val composite: CompositeDecoder = decoder.beginStructure(descriptor)
        var kind: String? = null
        var data: String? = null
        loop@ while (true) {
            when (val i = composite.decodeElementIndex(descriptor)) {
                CompositeDecoder.DECODE_DONE -> break@loop
                0 -> kind = composite.decodeStringElement(descriptor, i)
                1 -> data = composite.decodeStringElement(descriptor, i)
                else -> throw SerializationException("Unknown index $i")
            }
        }
        composite.endStructure(descriptor)
        return deserialize(Pair(kind ?: "", data ?: ""))
    }

    private const val FUTURE = "future"
    private const val FUTURE_YEAR_OFFSET = "future_year_offset"
    private const val FOR_DATE = "fordate"
    private const val FOR_MONTH = "for_month"
    private const val FOR_MONTH_OFFSET = "for_month_offset"
    private const val FOR_YEAR_OFFSET = "for_year_offset"

    fun serialize(obj: Reservation.Filter): Pair<String, String> {
        return when (obj) {
            is Reservation.Filter.Future -> FUTURE to ""
            is Reservation.Filter.FutureYearOffset -> FUTURE_YEAR_OFFSET to obj.offset.toString()
            is Reservation.Filter.ForDate -> FOR_DATE to DateSerializer.serialize(obj.date)
            is Reservation.Filter.ForMonth -> FOR_MONTH to DateSerializer.serialize(obj.date)
            is Reservation.Filter.ForMonthOffset -> FOR_MONTH_OFFSET to obj.offset.toString()
            is Reservation.Filter.ForYearOffset -> FOR_YEAR_OFFSET to obj.offset.toString()
        }
    }

    fun deserialize(pair: Pair<String, String>): Reservation.Filter {
        return when (pair.first) {
            FUTURE -> Reservation.Filter.Future
            FUTURE_YEAR_OFFSET -> Reservation.Filter.FutureYearOffset(pair.second.toInt())
            FOR_DATE -> Reservation.Filter.ForDate(DateSerializer.deserialize(pair.second))
            FOR_MONTH -> Reservation.Filter.ForMonth(DateSerializer.deserialize(pair.second))
            FOR_MONTH_OFFSET -> Reservation.Filter.ForMonthOffset(pair.second.toInt())
            FOR_YEAR_OFFSET -> Reservation.Filter.ForYearOffset(pair.second.toInt())
            else -> Reservation.Filter.Future
        }
    }
}
