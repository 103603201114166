package techla.base

typealias Either<L, R> = Either2<L, R>

sealed class Either2<out L, out R> {
    private data class Left<L>(val value: L) : Either2<L, Nothing>()
    private data class Right<R>(val value: R) : Either2<Nothing, R>()

    val isLeft: Boolean get() = this is Left
    val isRight: Boolean get() = this is Right

    fun leftOrNull(): L? = (this as? Left)?.value
    fun rightOrNull(): R? = (this as? Right)?.value

    fun <T> fold(onLeft: (L) -> T, onRight: (R) -> T): T =
        when (this) {
            is Left -> onLeft(value)
            is Right -> onRight(value)
        }

    fun <T> mapLeft(f: (L) -> T) =
        fold(onLeft = { Left(f(it)) }, onRight = { Right(it) })

    fun <R, T> flatMapLeft(f: (L) -> Either2<T, R>) =
        fold(onLeft = { f(it) }, onRight = { Right(it) })

    fun <T> mapRight(f: (R) -> T) =
        fold(onLeft = { Left(it) }, onRight = { Right(f(it)) })

    fun <L, T> flatMapRight(f: (R) -> Either2<L, T>) =
        fold(onLeft = { Left(it) }, onRight = { f(it) })

    override fun toString(): String {
        return when (this) {
            is Left -> "Either2(Left: $value)"
            is Right -> "Either2(Right: $value)"
        }
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other !is Either2<*, *>) return false
        return when {
            this is Left && other is Left<*> -> value == other.value
            this is Right && other is Right<*> -> value == other.value
            else -> false
        }
    }

    override fun hashCode(): Int {
        return when (this) {
            is Left -> value?.hashCode() ?: 0
            is Right -> value?.hashCode() ?: 0
        }
    }

    companion object {
        fun <L, R> ofLeft(left: L): Either2<L, R> = Left(left)
        fun <L, R> ofRight(right: R): Either2<L, R> = Right(right)
    }
}

fun <L, R> leftOf2(left: L): Either2<L, R> = Either2.ofLeft(left)
fun <L, R> rightOf2(right: R): Either2<L, R> = Either2.ofRight(right)

fun <L, R> leftOf(left: L): Either2<L, R> = leftOf2(left)
fun <L, R> rightOf(right: R): Either2<L, R> = rightOf2(right)

@Deprecated("Use mapLeft or mapRight", ReplaceWith("mapRight(f)"))
fun <L, R, T> Either2<L, R>.map(f: (R) -> T) = mapRight(f)

@Deprecated("Use flatMapLeft or flatMapRight", ReplaceWith("flatMapRight(f)"))
fun <L, R, T> Either2<L, R>.flatMap(f: (R) -> Either2<L, T>) = flatMapRight(f)

sealed class Either3<out L, out M, out R> {
    private data class Left<L>(val value: L) : Either3<L, Nothing, Nothing>()
    private data class Middle<M>(val value: M) : Either3<Nothing, M, Nothing>()
    private data class Right<R>(val value: R) : Either3<Nothing, Nothing, R>()

    val isLeft: Boolean get() = this is Left
    val isMiddle: Boolean get() = this is Middle
    val isRight: Boolean get() = this is Right

    fun leftOrNull(): L? = (this as? Left)?.value
    fun middleOrNull(): M? = (this as? Middle)?.value
    fun rightOrNull(): R? = (this as? Right)?.value

    fun <T> fold(onLeft: (L) -> T, onMiddle: (M) -> T, onRight: (R) -> T): T =
        when (this) {
            is Left -> onLeft(value)
            is Middle -> onMiddle(value)
            is Right -> onRight(value)
        }

    fun <T> mapLeft(f: (L) -> T) =
        fold(onLeft = { Left(f(it)) }, onMiddle = { Middle(it) }, onRight = { Right(it) })

    fun <M, R, T> flatMapLeft(f: (L) -> Either3<T, M, R>) =
        fold(onLeft = { f(it) }, onMiddle = { Middle(it) }, onRight = { Right(it) })

    fun <T> mapMiddle(f: (M) -> T) =
        fold(onLeft = { Left(it) }, onMiddle = { Middle(f(it)) }, onRight = { Right(it) })

    fun <L, R, T> flatMapMiddle(f: (M) -> Either3<L, T, R>) =
        fold(onLeft = { Left(it) }, onMiddle = { f(it) }, onRight = { Right(it) })

    fun <T> mapRight(f: (R) -> T) =
        fold(onLeft = { Left(it) }, onMiddle = { Middle(it) }, onRight = { Right(f(it)) })

    fun <L, M, T> flatMapRight(f: (R) -> Either3<L, M, T>) =
        fold(onLeft = { Left(it) }, onMiddle = { Middle(it) }, onRight = { f(it) })

    override fun toString(): String {
        return when (this) {
            is Left -> "Either3(Left: $value)"
            is Middle -> "Either3(Left: $value)"
            is Right -> "Either3(Right: $value)"
        }
    }

    override fun equals(other: Any?): Boolean {
        if (this === other) return true
        if (other !is Either3<*, *, *>) return false
        return when {
            this is Left && other is Left<*> -> value == other.value
            this is Middle && other is Middle<*> -> value == other.value
            this is Right && other is Right<*> -> value == other.value
            else -> false
        }
    }

    override fun hashCode(): Int {
        return when (this) {
            is Left -> value?.hashCode() ?: 0
            is Middle -> value?.hashCode() ?: 0
            is Right -> value?.hashCode() ?: 0
        }
    }

    companion object {
        fun <L, M, R> ofLeft(left: L): Either3<L, M, R> = Left(left)
        fun <L, M, R> ofMiddle(middle: M): Either3<L, M, R> = Middle(middle)
        fun <L, M, R> ofRight(right: R): Either3<L, M, R> = Right(right)
    }
}

fun <L, M, R> leftOf3(left: L): Either3<L, M, R> = Either3.ofLeft(left)
fun <L, M, R> middleOf3(middle: M): Either3<L, M, R> = Either3.ofMiddle(middle)
fun <L, M, R> rightOf3(right: R): Either3<L, M, R> = Either3.ofRight(right)
