package techla.base

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(with = EnvironmentSerializer::class)
sealed class Environment {
    object None : Environment()
    object Local : Environment()
    object Sandbox : Environment()
    object Production : Environment()

    override fun toString(): String {
        return when (this) {
            is None -> "Environment.None"
            is Local -> "Environment.Local"
            is Sandbox -> "Environment.Sandbox"
            is Production -> "Environment.Production"
        }
    }
}

object EnvironmentSerializer : KSerializer<Environment> {
    override val descriptor: SerialDescriptor
        = PrimitiveSerialDescriptor("EnvironmentSerializer", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: Environment)
        = encoder.encodeString(serialize(value))

    override fun deserialize(decoder: Decoder): Environment
        = deserialize(decoder.decodeString())

    private const val LOCAL = "loc"
    private const val SANDBOX = "sbx"
    private const val PRODUCTION = "prd"

    fun serialize(obj: Environment): String {
        return when (obj) {
            is Environment.Local -> LOCAL
            is Environment.Sandbox -> SANDBOX
            is Environment.Production -> PRODUCTION
            is Environment.None -> throw TechlaError.PreconditionFailed("Environment.None cannot be serialized")
        }
    }

    fun deserialize(string: String): Environment {
        return when (string) {
            LOCAL -> Environment.Local
            SANDBOX -> Environment.Sandbox
            PRODUCTION -> Environment.Production
            else -> Environment.None
        }
    }
}
