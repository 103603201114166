package components

import emotion.react.css
import mui.material.FormControl
import mui.material.MenuItem
import mui.material.Select
import mui.material.Size
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.label
import support.DesignSystem
import support.HetchColors
import support.hetchTheme
import web.cssom.*

external interface HSelectProps : Props {
    var design: DesignSystem.Input.Select
    var onChange: (String?) -> Unit
    var value: String?
    var fullWidth: Boolean
    var withLabel: Boolean
}

val HSelect = FC<HSelectProps> { props ->
    FormControl {
        fullWidth = props.fullWidth
        if (props.withLabel) {
            label {
                css {
                    paddingBottom = 8.px
                }
                htmlFor = props.design.name
                +props.design.label
            }
        }

        Select {
            css {
                backgroundColor = important(Color(HetchColors.white))
                color = important(Color(HetchColors.black))
                ".MuiSvgIcon-root" {
                    color = important(Color(HetchColors.black))
                }
                ".MuiOutlinedInput-notchedOutline" {
                    border = important(Border(1.px, LineStyle.solid, hetchTheme.palette.primary.main))
                }
                ".MuiSelect-outlined" {
                    border = important(Border(1.px, LineStyle.solid, hetchTheme.palette.primary.main))
                }
            }

            fullWidth = props.fullWidth
            size = Size.small
            name = props.design.name
            value = props.value ?: ""
            displayEmpty = true
            id = props.design.name
            label = ReactNode(props.design.label)

            onChange = { event, _ ->
                props.onChange(event.target.value)
            }

            props.design.options.map { option ->
                MenuItem {
                    value = option.value
                    +option.title
                }
            }
        }
    }
}