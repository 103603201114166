package services

import kotlinx.browser.localStorage
import support.*
import techla.base.*
import techla.guard.*

suspend fun Store.createApplicationAuthentication(): Outcome<ApplicationAuthentication> {
    val api = guardAPI
    api.token = applicationToken
    val create = ApplicationAuthentication.Create(
        applicationKey = deployment.applicationKey,
        applicationSecret = deployment.applicationSecret,
        device = device,
    )
    return measureAPI(GuardResource.CreateApplicationAuthentication(create), api) {
        api.createApplicationAuthentication(create).onNotSuccess { techla_log("WARN: $it") }
    }
}


suspend fun Store.createUserAuthentication(email: String?): Outcome<UserAuthentication> {
    val api = guardAPI
    api.token = applicationToken
    val create = UserAuthentication.Create(email = email)
    return measureAPI(GuardResource.CreateUserAuthentication(create), api) {
        api.createUserAuthentication(create).onNotSuccess { techla_log("WARN: $it") }
    }
}


suspend fun Store.verifyUserAuthentication(code: String?): Outcome<UserAuthentication> {
    if (userAuthenticationId == null) return failedOf(TechlaError.BadRequest("userAuthenticationId missing (verifyUserAuthentication)"))
    val api = guardAPI
    api.token = applicationToken
    val verify = UserAuthentication.Verify(code = code)
    return measureAPI(GuardResource.VerifyUserAuthentication(userAuthenticationId, verify), api) {
        api.verifyUserAuthentication(userAuthenticationId, verify).onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.refreshUserAuthentication(): Outcome<UserAuthentication> {
    val id =
        userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId needed to refresh token"))
    val api = guardAPI
    api.token = applicationToken
    return api.getUserAuthentication(id).fold({
        when (it.status) {
            is UserAuthentication.Status.Complete -> successfulOf(it)
            is UserAuthentication.Status.Expired -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is expired"))
            is UserAuthentication.Status.Cancelled -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is cancelled"))
            is UserAuthentication.Status.Failed -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is failed"))
            is UserAuthentication.Status.Outstanding -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is outstanding"))
            is UserAuthentication.Status.Verified -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is verified"))
        }
    }, { failedOf(TechlaError.BadRequest("WARN: $it")) }, { failedOf(it) })
}


suspend fun <T> Store.withUserToken(block: suspend (Store) -> Outcome<T>): ActionOutcome<T> {
    val expired = tokenExpiresAt?.hasPassed() ?: true
    return if (expired) {
        techla_log("STORE: Token expired (${tokenExpiresAt}), refreshing")
        refreshUserAuthentication().flatMap { userAuthentication ->
            val action = Store.Action.TokenRefresh(userAuthentication.tokens)
            val updated = reduce(action)
            block(updated).map { listOf(action) to it }
        }
    } else {
        block(this).noActions()
    }
}

suspend fun Store.me(): ActionOutcome<Me> = withUserToken { updated ->
    val api = updated.guardAPI
    api.token = updated.userToken

    measureAPI(GuardResource.Me, api) {
        api.me().onNotSuccess { techla_log("WARN: $it") }
    }
}


suspend fun Store.listProfiles(): ActionOutcome<List<Profile>> = withUserToken { updated ->
    val api = updated.guardAPI
    api.token = updated.adminToken

    measureAPI(GuardResource.ListProfiles, api) {
        api.listProfiles().onNotSuccess { techla_log("WARN: $it") }
    }
}


suspend fun Store.createProfile(create: Profile.Create): ActionOutcome<Profile> = withUserToken { updated ->
    val api = updated.guardAPI
    api.token = updated.adminToken

    measureAPI(GuardResource.CreateProfile(create), api) {
        api.createProfile(create).onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.editProfile(id: Identifier<Profile>, edit: Profile.Edit): ActionOutcome<Profile> = withUserToken { updated ->
    val api = updated.guardAPI
    api.token = updated.adminToken

    measureAPI(GuardResource.EditProfile(id, edit), api) {
        api.editProfile(id, edit).onNotSuccess { techla_log("WARN: $it") }
    }
}


suspend fun Store.deleteProfile(id: Identifier<Profile>): ActionOutcome<Unit> = withUserToken { updated ->
    val api = updated.guardAPI
    api.token = updated.adminToken

    measureAPI(GuardResource.DeleteProfile(id), api) {
        api.deleteProfile(id).onNotSuccess { techla_log("WARN: $it") }
    }
}


val Store.guardAPI
    get() = GuardAPI(httpClient).also { api ->
        api.host = if (deployment.isSandbox) GuardAPI.sandbox else GuardAPI.shared
    }