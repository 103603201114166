package techla.guard

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.*
import techla.base.*

@Serializable
data class Group(
    val id: Identifier<Group>,
    val key: Key<Group>,
    val name: String,
    val kind: Kind,
    val status: Status,
    val order: Int,
    val visualization: Visualization,
    val members: List<Identifier<Profile>>,
    val leader: Identifier<Profile>?,
    val numberOfInvites: Int,
    val createdAt: Date,
) {
    @Serializable(with = GroupKindSerializer::class)
    sealed class Kind(private val _discriminator: String) {
        object Unknown : Kind(GroupKindSerializer.UNKNOWN)
        object None : Kind(GroupKindSerializer.NONE)
        data class Custom(val name: String) : Kind(GroupKindSerializer.CUSTOM)

        companion object;
        val rawValue: String get() = _discriminator

        override fun toString(): String {
            return when (this) {
                is Unknown -> "Kind.Unknown"
                is None -> "Kind.None"
                is Custom -> "Kind.Custom($name)"
            }
        }
    }

    @Serializable(with = GroupStatusSerializer::class)
    sealed class Status(private val _discriminator: String) {
        data object Unknown : Status(GroupStatusSerializer.UNKNOWN)
        data object None : Status(GroupStatusSerializer.NONE)
        data object Active : Status(GroupStatusSerializer.ACTIVE)
        data object Archived : Status(GroupStatusSerializer.ARCHIVED)
        data object Inactive : Status(GroupStatusSerializer.INACTIVE)

        val rawValue: String get() = _discriminator

        companion object;
        override fun toString(): String {
            return when (this) {
                is Unknown -> "Group.Status.Unknown"
                is None -> "Group.Status.None"
                is Active -> "Group.Status.Active"
                is Archived -> "Group.Status.Archived"
                is Inactive -> "Group.Status.Inactive"
            }
        }
    }

    @Serializable(with = GroupVisualizationSerializer::class)
    sealed class Visualization(private val _discriminator: String) {
        object Unknown : Visualization(GroupVisualizationSerializer.UNKNOWN)
        object None : Visualization(GroupVisualizationSerializer.NONE)
        data class Predefined(val template: String) : Visualization(GroupVisualizationSerializer.PREDEFINED)
        data class AssetImage(val assetId: Identifier<out BaseAsset>) : Visualization(GroupVisualizationSerializer.ASSET_IMAGE)
        data class AssetMovie(val assetId: Identifier<out BaseAsset>) : Visualization(GroupVisualizationSerializer.ASSET_MOVIE)

        val rawValue: String get() = _discriminator

        companion object;
        override fun toString(): String {
            return when (this) {
                is Unknown -> "Group.Visualization.Unknown"
                is None -> "Group.Visualization.None"
                is Predefined -> "Group.Visualization.Predefined"
                is AssetImage -> "Group.Visualization.AssetImage"
                is AssetMovie -> "Group.Visualization.AssetMovie"
            }
        }
    }

    companion object {
        val STANDARD = Key<Group>("std")
    }

    val isStandard get() = key == STANDARD

    @Serializable
    data class Create(
        val key: Key<Group>,
        val name: String,
        val kind: Kind = Kind.None,
        val status: Status,
        val order: Int = 0,
        val visualization: Visualization,
        val join: Boolean,
    )

    @Serializable
    data class Edit(
        val key: Modification<Key<Group>> = Modification.Unmodified,
        val name: Modification<String> = Modification.Unmodified,
        val kind: Modification<Kind> = Modification.Unmodified,
        val status: Modification<Status> = Modification.Unmodified,
        val order: Modification<Int> = Modification.Unmodified,
        val visualization: Modification<Visualization> = Modification.Unmodified,
    )

    @Serializable
    data class Members(
        val set: Modification<List<Identifier<in Profile>>> = Modification.Unmodified,
        val add: Modification<List<Identifier<in Profile>>> = Modification.Unmodified,
        val remove: Modification<List<Identifier<in Profile>>> = Modification.Unmodified,
    )
}

data class GroupKindComponents(
    val rawValue: String,
    val name: String? = null,
)

fun Group.Kind.flatten() =
    when (this) {
        is Group.Kind.None -> GroupKindComponents(rawValue)
        is Group.Kind.Custom -> GroupKindComponents(rawValue, name = name)
        is Group.Kind.Unknown ->
            throw TechlaError.PreconditionFailed("Group.Kind.Unknown cannot be flatten")
    }

fun Group.Kind.Companion.unflatten(components: GroupKindComponents) =
    when (components.rawValue) {
        GroupKindSerializer.NONE -> Group.Kind.None
        GroupKindSerializer.CUSTOM -> Group.Kind.Custom(name = components.name!!)
        else -> Group.Kind.Unknown
    }

object GroupKindSerializer : KSerializer<Group.Kind> {
    const val UNKNOWN = "unknown"
    const val NONE = "none"
    const val CUSTOM = "custom"

    private const val NAME = "name"

    override val descriptor: SerialDescriptor =
        String.serializer().descriptor

    override fun serialize(encoder: Encoder, value: Group.Kind) {
        require(encoder is JsonEncoder)

        val jsonObject = buildJsonObject {
            val components = value.flatten()
            put("_discriminator", components.rawValue)
            components.name?.let { put(NAME, it) }
        }

        encoder.encodeJsonElement(jsonObject)
    }

    override fun deserialize(decoder: Decoder): Group.Kind {
        require(decoder is JsonDecoder)
        val jsonObject = decoder.decodeJsonElement().jsonObject
        val discriminator = jsonObject["_discriminator"]
        if (discriminator == null || discriminator !is JsonPrimitive) {
            return Group.Kind.Unknown
        }

        val components = GroupKindComponents(
            rawValue = discriminator.content,
            name = jsonObject[NAME].parseString(),
        )

        return Group.Kind.unflatten(components)
    }
}

data class GroupStatusComponents(
    val rawValue: String,
)

fun Group.Status.flatten() =
    when (this) {
        is Group.Status.None -> GroupStatusComponents(rawValue)
        is Group.Status.Active -> GroupStatusComponents(rawValue)
        is Group.Status.Archived -> GroupStatusComponents(rawValue)
        is Group.Status.Inactive -> GroupStatusComponents(rawValue)
        is Group.Status.Unknown ->
            throw TechlaError.PreconditionFailed("Group.Status.Unknown cannot be flatten")
    }

fun Group.Status.Companion.unflatten(components: GroupStatusComponents) =
    when (components.rawValue) {
        GroupStatusSerializer.NONE -> Group.Status.None
        GroupStatusSerializer.ACTIVE -> Group.Status.Active
        GroupStatusSerializer.ARCHIVED -> Group.Status.Archived
        GroupStatusSerializer.INACTIVE -> Group.Status.Inactive
        else -> Group.Status.Unknown
    }

object GroupStatusSerializer : KSerializer<Group.Status> {
    const val UNKNOWN = "unknown"
    const val NONE = "none"
    const val ACTIVE = "active"
    const val ARCHIVED = "archived"
    const val INACTIVE = "inactive"

    override val descriptor: SerialDescriptor =
        String.serializer().descriptor

    override fun deserialize(decoder: Decoder): Group.Status {
        require(decoder is JsonDecoder)

        val jsonObject = decoder.decodeJsonElement().jsonObject

        return deserialize(jsonObject)
    }

    fun deserialize(jsonObject: JsonObject): Group.Status {
        val discriminator = jsonObject["_discriminator"]
        if (discriminator == null || discriminator !is JsonPrimitive) {
            return Group.Status.Unknown
        }

        val components = GroupStatusComponents(
            rawValue = discriminator.content,
        )

        return Group.Status.unflatten(components)
    }

    override fun serialize(encoder: Encoder, value: Group.Status) {
        require(encoder is JsonEncoder)

        encoder.encodeJsonElement(serialize(value))
    }

    fun serialize(value: Group.Status): JsonObject {
        return buildJsonObject {
            val components = value.flatten()
            put("_discriminator", components.rawValue)
        }
    }
}

data class GroupVisualizationComponents(
    val rawValue: String,
    val template: String? = null,
    val assetId: Identifier<out BaseAsset>? = null,
)

fun Group.Visualization.flatten() =
    when (this) {
        is Group.Visualization.None -> GroupVisualizationComponents(rawValue)
        is Group.Visualization.Predefined -> GroupVisualizationComponents(rawValue, template = template)
        is Group.Visualization.AssetImage -> GroupVisualizationComponents(rawValue, assetId = assetId)
        is Group.Visualization.AssetMovie -> GroupVisualizationComponents(rawValue, assetId = assetId)
        is Group.Visualization.Unknown ->
            throw TechlaError.PreconditionFailed("Group.Visualization.Unknown cannot be flatten")
    }

fun Group.Visualization.Companion.unflatten(components: GroupVisualizationComponents) =
    when (components.rawValue) {
        GroupVisualizationSerializer.NONE -> Group.Visualization.None
        GroupVisualizationSerializer.PREDEFINED -> Group.Visualization.Predefined(template = components.template!!)
        GroupVisualizationSerializer.ASSET_IMAGE -> Group.Visualization.AssetImage(assetId = components.assetId!!)
        GroupVisualizationSerializer.ASSET_MOVIE -> Group.Visualization.AssetMovie(assetId = components.assetId!!)
        else -> Group.Visualization.Unknown
    }

object GroupVisualizationSerializer : KSerializer<Group.Visualization> {
    const val UNKNOWN = "unknown"
    const val NONE = "none"
    const val PREDEFINED = "predefined"
    const val ASSET_IMAGE = "asset_image"
    const val ASSET_MOVIE = "asset_movie"

    private const val TEMPLATE = "template"
    private const val ASSET = "asset"

    override val descriptor: SerialDescriptor =
        String.serializer().descriptor

    override fun serialize(encoder: Encoder, value: Group.Visualization) {
        require(encoder is JsonEncoder)

        encoder.encodeJsonElement(serialize(value))
    }

    fun serialize(value: Group.Visualization): JsonObject {
        return buildJsonObject {
            val components = value.flatten()
            put("_discriminator", components.rawValue)
            components.template?.let { put(TEMPLATE, it) }
            components.assetId?.let { put(ASSET, it.rawValue) }
        }
    }

    override fun deserialize(decoder: Decoder): Group.Visualization {
        require(decoder is JsonDecoder)

        val jsonObject = decoder.decodeJsonElement().jsonObject

        return deserialize(jsonObject)
    }

    fun deserialize(jsonObject: JsonObject): Group.Visualization {
        val discriminator = jsonObject["_discriminator"]
        if (discriminator == null || discriminator !is JsonPrimitive) {
            return Group.Visualization.Unknown
        }

        val components = GroupVisualizationComponents(
            rawValue = discriminator.content,
            template = jsonObject[TEMPLATE].parseString(),
            assetId = jsonObject[ASSET].parseIdentifier(),
        )

        return Group.Visualization.unflatten(components)
    }
}
