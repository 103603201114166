package techla.base

import io.ktor.client.HttpClient

open class BaseAPI(val client: HttpClient) {
    var host: String? = null
    var token: String? = null
    var additionalHeaders: List<Pair<String, String>>? = null
    var cache: Cache? = null

    companion object {
        const val HEADER_TECHLA_WARNINGS = "X-Techla-Warnings"
        const val HEADER_TECHLA_RETRIES = "X-Techla-Retries"
    }
}
