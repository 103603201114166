package techla.guard

import kotlinx.serialization.Serializable
import techla.base.Identifier
import techla.base.Key

@Serializable
data class Service(
    val id: Identifier<Service>,
    val key: Key<Service>,
    val name: String,
) {
    companion object {
        val TECH_ACCOUNT = Key<Service>("acc")
        val TECH_AGREEMENT = Key<Service>("agr")
        val TECH_AI = Key<Service>("ai")
        val TECH_BILLING = Key<Service>("bil")
        val TECH_CARD = Key<Service>("crd")
        val TECH_CONTENT = Key<Service>("cms")
        val TECH_CONTROL = Key<Service>("hub")
        val TECH_CONVERSATION = Key<Service>("cvn")
        val TECH_FORM = Key<Service>("frm")
        val TECH_GUARD = Key<Service>("grd")
        val TECH_LOYALTY = Key<Service>("loy")
        val TECH_NOTIFICATION = Key<Service>("not")
        val TECH_ORDER = Key<Service>("ord")
        val TECH_PAYMENT = Key<Service>("pay")
        val TECH_PERSONAL = Key<Service>("prs")
        val TECH_RESERVATION = Key<Service>("res")
        val TECH_SOCIAL = Key<Service>("soc")
        val TECH_STORAGE = Key<Service>("sto")
    }
}
