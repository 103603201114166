package techla.guard

import io.ktor.client.HttpClient
import kotlinx.serialization.builtins.ListSerializer
import techla.base.*

class GuardAPI(client: HttpClient) : BaseAPI(client) {
    companion object {
        const val local = "http://localhost:8080"
        const val sandbox = "https://sandbox.techla.io"
        const val shared = "https://api.techla.io"
    }

    init {
        host = sandbox
    }

    /**
     * Application authentication
     */

    suspend fun createApplicationAuthentication(create: ApplicationAuthentication.Create): Outcome<ApplicationAuthentication> {
        return GuardAPIResource.CreateApplicationAuthentication(create)
            .call(this)
            .decode(ApplicationAuthentication.serializer())
    }

    suspend fun getApplicationAuthentication(id: Identifier<ApplicationAuthentication>): Outcome<ApplicationAuthentication> {
        return GuardAPIResource.GetApplicationAuthentication(id)
            .call(this)
            .decode(ApplicationAuthentication.serializer())
    }

    suspend fun listApplicationAuthentications(): Outcome<List<ApplicationAuthentication>> {
        return GuardAPIResource.ListApplicationAuthentications
            .call(this)
            .decode(ListSerializer(ApplicationAuthentication.serializer()))
    }

    suspend fun listApplicationAuthentications(index: PageIndex): Outcome<PageContent<ApplicationAuthentication>> {
        return GuardAPIResource.ListApplicationAuthenticationsWithPaging(index)
            .call(this)
            .decode(PageContent.serializer(ApplicationAuthentication.serializer()))
    }

    /**
     * User authentication
     */

    suspend fun createUserAuthentication(create: UserAuthentication.Create): Outcome<UserAuthentication> {
        return GuardAPIResource.CreateUserAuthentication(create)
            .call(this)
            .decode(UserAuthentication.serializer())
    }

    suspend fun verifyUserAuthentication(id: Identifier<UserAuthentication>, verify: UserAuthentication.Verify): Outcome<UserAuthentication> {
        return GuardAPIResource.VerifyUserAuthentication(id, verify)
            .call(this)
            .decode(UserAuthentication.serializer())
    }

    suspend fun deleteUserAuthentication(id: Identifier<UserAuthentication>): Outcome<Unit> {
        return GuardAPIResource.DeleteUserAuthentication(id)
            .call(this)
            .map {}
    }

    suspend fun getUserAuthentication(id: Identifier<UserAuthentication>): Outcome<UserAuthentication> {
        return GuardAPIResource.GetUserAuthentication(id)
            .call(this)
            .decode(UserAuthentication.serializer())
    }

    suspend fun listUserAuthentications(): Outcome<List<UserAuthentication>> {
        return GuardAPIResource.ListUserAuthentications
            .call(this)
            .decode(ListSerializer(UserAuthentication.serializer()))
    }

    suspend fun listUserAuthentications(index: PageIndex, statuses: List<UserAuthentication.Status>? = null): Outcome<PageContent<UserAuthentication>> {
        return GuardAPIResource.ListUserAuthenticationsWithPaging(index, statuses)
            .call(this)
            .decode(PageContent.serializer(UserAuthentication.serializer()))
    }

    /**
     * Me
     */

    suspend fun me(): Outcome<Me> {
        return GuardAPIResource.Me
            .call(this)
            .decode(Me.serializer())
    }

    suspend fun editMe(edit: Profile.MeEdit): Outcome<Me> {
        return GuardAPIResource.EditMe(edit)
            .call(this)
            .decode(Me.serializer())
    }

    /**
     * Profile
     */

    suspend fun listProfiles(): Outcome<List<Profile>> {
        return GuardAPIResource.ListProfiles
            .call(this)
            .decode(ListSerializer(Profile.serializer()))
    }

    suspend fun listProfiles(index: PageIndex, sources: List<Profile.Source>? = null): Outcome<PageContent<Profile>> {
        return GuardAPIResource.ListProfilesWithPaging(index, sources)
            .call(this)
            .decode(PageContent.serializer(Profile.serializer()))
    }

    suspend fun getProfile(id: Identifier<in Profile>): Outcome<Profile> {
        return GuardAPIResource.GetProfile(id)
            .call(this)
            .decode(Profile.serializer())
    }

    suspend fun createProfile(create: Profile.Create): Outcome<Profile> {
        return GuardAPIResource.CreateProfile(create)
            .call(this)
            .decode(Profile.serializer())
    }

    suspend fun editProfile(id: Identifier<in Profile>, edit: Profile.Edit): Outcome<Profile> {
        return GuardAPIResource.EditProfile(id, edit)
            .call(this)
            .decode(Profile.serializer())
    }

    suspend fun deleteProfile(id: Identifier<in Profile>): Outcome<Unit> {
        return GuardAPIResource.DeleteProfile(id)
            .call(this)
            .map {}
    }

    suspend fun forgetProfile(id: Identifier<in Profile>): Outcome<Unit> {
        return GuardAPIResource.ForgetProfile(id)
            .call(this)
            .map {}
    }

    /**
     * Group
     */

    suspend fun myGroups(): Outcome<List<Group>> {
        return GuardAPIResource.MyGroups
            .call(this)
            .decode(ListSerializer(Group.serializer()))
    }

    suspend fun myGroups(index: PageIndex): Outcome<PageContent<Group>> {
        return GuardAPIResource.MyGroupsWithPaging(index)
            .call(this)
            .decode(PageContent.serializer(Group.serializer()))
    }

    suspend fun listGroups(): Outcome<List<Group>> {
        return GuardAPIResource.ListGroups
            .call(this)
            .decode(ListSerializer(Group.serializer()))
    }

    suspend fun listGroups(profileId: Identifier<in Profile>): Outcome<List<Group>> {
        return GuardAPIResource.ListGroupsWithProfile(profileId)
            .call(this)
            .decode(ListSerializer(Group.serializer()))
    }

    suspend fun listGroups(index: PageIndex, statuses: List<Group.Status>? = null, profileIds: List<Identifier<in Profile>>? = null, predefined: List<Group.Visualization.Predefined>? = null): Outcome<PageContent<Group>> {
        return GuardAPIResource.ListGroupsWithPaging(index, statuses, profileIds, predefined)
            .call(this)
            .decode(PageContent.serializer(Group.serializer()))
    }

    suspend fun getGroup(id: Identifier<Group>): Outcome<Group> {
        return GuardAPIResource.GetGroup(id)
            .call(this)
            .decode(Group.serializer())
    }

    suspend fun createGroup(create: Group.Create): Outcome<Group> {
        return GuardAPIResource.CreateGroup(create)
            .call(this)
            .decode(Group.serializer())
    }

    suspend fun editGroup(id: Identifier<Group>, edit: Group.Edit): Outcome<Group> {
        return GuardAPIResource.EditGroup(id, edit)
            .call(this)
            .decode(Group.serializer())
    }

    suspend fun deleteGroup(id: Identifier<Group>): Outcome<Unit> {
        return GuardAPIResource.DeleteGroup(id)
            .call(this)
            .map {}
    }

    suspend fun editMembers(id: Identifier<Group>, members: Group.Members): Outcome<Group> {
        return GuardAPIResource.EditMembers(id, members)
            .call(this)
            .decode(Group.serializer())
    }

    /**
     * Invite
     */

    suspend fun listInvites(): Outcome<List<Invite>> {
        return GuardAPIResource.ListInvites
            .call(this)
            .decode(ListSerializer(Invite.serializer()))
    }

    suspend fun listInvites(index: PageIndex): Outcome<PageContent<Invite>> {
        return GuardAPIResource.ListInvitesWithPaging(index)
            .call(this)
            .decode(PageContent.serializer(Invite.serializer()))
    }

    suspend fun getInvite(id: Identifier<Invite>): Outcome<Invite> {
        return GuardAPIResource.GetInvite(id)
            .call(this)
            .decode(Invite.serializer())
    }

    suspend fun createInvite(create: Invite.Create): Outcome<Invite> {
        return GuardAPIResource.CreateInvite(create)
            .call(this)
            .decode(Invite.serializer())
    }

    suspend fun deleteInvite(id: Identifier<Invite>): Outcome<Unit> {
        return GuardAPIResource.DeleteInvite(id)
            .call(this)
            .map {}
    }

    suspend fun acceptInvite(accept: Invite.Accept): Outcome<Unit> {
        return GuardAPIResource.AcceptInvite(accept)
            .call(this)
            .map {}
    }

    suspend fun acceptInviteByProcuration(procuration: Invite.Procuration): Outcome<Unit> {
        return GuardAPIResource.AcceptInviteByProcuration(procuration)
            .call(this)
            .map {}
    }

    /**
     * Application
     */

    suspend fun listApplications(): Outcome<List<Application>> {
        return GuardAPIResource.ListApplications
            .call(this)
            .decode(ListSerializer(Application.serializer()))
    }

    suspend fun listApplications(index: PageIndex): Outcome<PageContent<Application>> {
        return GuardAPIResource.ListApplicationsWithPaging(index)
            .call(this)
            .decode(PageContent.serializer(Application.serializer()))
    }

    suspend fun getApplication(id: Identifier<Application>): Outcome<Application> {
        return GuardAPIResource.GetApplication(id)
            .call(this)
            .decode(Application.serializer())
    }

    /**
     * Service
     */

    suspend fun listServices(): Outcome<List<Service>> {
        return GuardAPIResource.ListServices
            .call(this)
            .decode(ListSerializer(Service.serializer()))
    }

    suspend fun listServices(index: PageIndex): Outcome<PageContent<Service>> {
        return GuardAPIResource.ListServicesWithPaging(index)
            .call(this)
            .decode(PageContent.serializer(Service.serializer()))
    }

    suspend fun getService(id: Identifier<Service>): Outcome<Service> {
        return GuardAPIResource.GetService(id)
            .call(this)
            .decode(Service.serializer())
    }

    /**
     * Workspace
     */

    suspend fun listWorkspaces(): Outcome<List<Workspace>> {
        return GuardAPIResource.ListWorkspaces
            .call(this)
            .decode(ListSerializer(Workspace.serializer()))
    }

    suspend fun listWorkspaces(index: PageIndex): Outcome<PageContent<Workspace>> {
        return GuardAPIResource.ListWorkspacesWithPaging(index)
            .call(this)
            .decode(PageContent.serializer(Workspace.serializer()))
    }

    suspend fun getWorkspace(id: Identifier<Workspace>): Outcome<Workspace> {
        return GuardAPIResource.GetWorkspace(id)
            .call(this)
            .decode(Workspace.serializer())
    }
}
