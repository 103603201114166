package techla.base

import io.ktor.http.Url
import kotlinx.serialization.KSerializer
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.nullable
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object UrlSerializer : KSerializer<Url> {
    override val descriptor: SerialDescriptor
        = PrimitiveSerialDescriptor("UrlSerializer", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: Url)
        = encoder.encodeString(serialize(value))

    override fun deserialize(decoder: Decoder): Url
        = deserialize(decoder.decodeString())

    fun serialize(obj: Url): String
        = obj.toString()

    fun deserialize(data: String): Url
        = Url(data)
}

val ListStringSerializer : KSerializer<List<String>>
    = ListSerializer(String.serializer())

val ListNullableStringSerializer : KSerializer<List<String?>>
    = ListSerializer(String.serializer().nullable)
