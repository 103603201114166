package screens

import kotlinx.browser.localStorage
import services.*
import support.*
import techla.base.*
import techla.guard.Token
import web.html.ButtonType

object EnterScreen {
    sealed class ViewModel(
        val emailInput: DesignSystem.Input.Email = DesignSystem.Input.Email(
            name = "email", label = "Email", value = "", required = true
        ),
        val submit: DesignSystem.Button = DesignSystem.Button(type = ButtonType.submit, text = "next"),
    ) {
        object None : ViewModel()
        object Ready : ViewModel()
        object Submitted : ViewModel()
        object Enter : ViewModel()
        data class Failed(
            val failure: DesignSystem.Failure,
        ) : ViewModel()

        fun ready() = Ready

        fun enter() = Enter

        fun submitted() = Submitted

        fun failed(failure: Either<List<Warning>, Throwable>, isSignedIn: Boolean): ViewModel = Failed(failure = failure(failure, isSignedIn))
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result, store.isSignedIn))

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val userId = localStorage.getItem("userId")
        if (userId != null) {
            store.createApplicationAuthentication().map {
                val actions = listOf(
                    Store.Action.UserAuthenticationStarted(userAuthenticationId = Identifier(userId)),
                    Store.Action.ApplicationAuthenticationCompleted(applicationToken = it.tokens.filterIsInstance<Token.Application>().first().token)
                )
                store.reduce(actions).refreshUserAuthentication().map { auth ->
                    val expired = auth.tokens.filterIsInstance<Token.User>().firstOrNull()?.expiresAt
                    if (expired?.hasPassed() == false) {
                        val action = Store.Action.UserAuthenticationCompleted(tokens = auth.tokens, profileId = auth.profileId)
                        store.reduce(actions + action).me().accumulate(actions + action).map { (action, me) ->
                            return sceneOf<ViewModel>(viewModel.enter(), action + Store.Action.GetMe(me = me))
                        }
                    }
                }
            }
        }

        return successfulOf(true).flatMap {
            store.createApplicationAuthentication()
        }.map {
            val action = Store.Action.ApplicationAuthenticationCompleted(applicationToken = it.tokens.filterIsInstance<Token.Application>().first().token)
            sceneOf<ViewModel>(viewModel.ready(), action)
        }.failed { scene.failed(result = it) }

    }

    suspend fun handleSubmit(scene: Scene.Input<ViewModel>, email: String): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return successfulOf(true).flatMap { store.createUserAuthentication(email = email) }.map {
            val action = Store.Action.UserAuthenticationStarted(userAuthenticationId = it.index.id)

            sceneOf<ViewModel>(viewModel.submitted(), listOf(action))
        }.failed { scene.failed(result = it) }
    }
}