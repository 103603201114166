package components

import StoreContext
import emotion.react.css
import mui.material.Container
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.img
import react.router.dom.Link
import react.useContext
import web.cssom.*


val HNavbar = FC<Props> {
    val (store, _) = useContext(StoreContext)

    Container {
        maxWidth = "xl"

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.center
                padding = Padding(32.px, 0.px, 32.px, 0.px)
            }

            a {
                href = "https://www.hetch.se/"
                img {
                    src = "/public/hetch_symbol_sm.svg"
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(2)

                if (store.isSignedIn && store.isAdmin) {
                    Link {
                        css {
                            color = Color("white")
                            textTransform = TextTransform.uppercase
                            textDecoration = None.none
                        }
                        to = "/book"

                        Typography {
                            sx {
                                color = Color.currentcolor
                            }
                            variant = TypographyVariant.body1
                            +"Book"
                        }
                    }
                    Link {
                        css {
                            color = Color("white")
                            textTransform = TextTransform.uppercase
                            textDecoration = None.none
                        }
                        to = "/users"

                        Typography {
                            sx {
                                color = Color.currentcolor
                            }
                            variant = TypographyVariant.body1
                            +"Users"
                        }
                    }
                }
            }
        }
    }
}