package techla.guard

import kotlinx.serialization.Serializable
import techla.base.Date
import techla.base.Identifier
import techla.base.Key

@Serializable
data class Invite(
    val id: Identifier<Invite>,
    val key: Key<Invite>,
    val groupId: Identifier<Group>,
    val issuedBy: Identifier<Profile>,
    val expiresAt: Date,
) {
    @Serializable
    data class Create(
        val group: Key<Group>,
    )

    @Serializable
    data class Accept(
        val invite: Key<Invite>,
    )

    @Serializable
    data class Procuration(
        val invite: Key<Invite>,
        val profileId: Identifier<Profile>,
    )
}
