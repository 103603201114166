package screens

import services.createProfile
import services.deleteProfile
import services.editProfile
import services.listProfiles
import support.*
import techla.base.*
import techla.guard.Profile
import web.html.ButtonType


object UsersScreen {
    data class State(
        val profile: Profile? = null
    )

    data class Texts(
        val created: String,
        val edit: String
    )

    sealed class ViewModel(
        open val state: State,
        open val texts: Texts,
        val openCreate: DesignSystem.Button = DesignSystem.Button(ButtonType.button, "New user"),
        val search: DesignSystem.Input.Text = DesignSystem.Input.Text(name = "search", label = "Search", value = ""),
        val email: DesignSystem.Input.Email = DesignSystem.Input.Email(
            name = "email", label = "Email", value = state.profile?.email ?: "", required = true
        ),
        val name: DesignSystem.Input.Text = DesignSystem.Input.Text(
            name = "name", label = "Name", value = state.profile?.lastName ?: "", required = true
        ),
        val company: DesignSystem.Input.Text = DesignSystem.Input.Text(
            name = "company", label = "Company", value = state.profile?.firstName ?: "", required = true
        ),
        val submit: DesignSystem.Button = DesignSystem.Button(type = ButtonType.submit, text = "Create user"),
        val ok: DesignSystem.Button = DesignSystem.Button(type = ButtonType.button, text = "Ok"),
        val deleteButton: DesignSystem.Button = DesignSystem.Button(
            type = ButtonType.button, text = "REMOVE", danger = true
        ),
        val editButton: DesignSystem.Button = DesignSystem.Button(
            type = ButtonType.button, text = "EDIT"
        ),
        val confirmDelete: DesignSystem.Button = DesignSystem.Button(
            type = ButtonType.button, text = "YES, REMOVE!", danger = true
        ),
        val updateTitle: String = if (state.profile == null) texts.created else texts.edit,
        val userName: String = state.profile?.lastName ?: "",
        val userCompany: String = state.profile?.firstName ?: "",
    ) {
        object None : ViewModel(state = State(null), texts = Texts("USER CREATED", "USER UPDATED"))
        data class Ready(override val state: State, override val texts: Texts) : ViewModel(state, texts)
        data class Creating(override val state: State, override val texts: Texts) : ViewModel(state, texts)

        data class Success(override val state: State, override val texts: Texts) : ViewModel(state, texts)

        data class Edit(override val state: State, override val texts: Texts) : ViewModel(state, texts)

        data class Deleting(override val state: State, override val texts: Texts) : ViewModel(state, texts)
        data class Deleted(override val state: State, override val texts: Texts) : ViewModel(state, texts)
        data class Failed(
            override val state: State,
            override val texts: Texts,
            val failure: DesignSystem.Failure,
        ) : ViewModel(state, texts)

        data class ValidationError(val warnings: List<Warning>) : ViewModel(state = State(null), texts = Texts("USER CREATED", "USER UPDATED"))

        fun ready(state: State, texts: Texts) = Ready(state = state, texts = texts)
        fun creating(state: State, texts: Texts) = Creating(state = state, texts = texts)

        fun success(state: State, texts: Texts) = Success(state = state, texts = texts)
        fun edit(state: State, texts: Texts): ViewModel = Edit(state = state, texts = texts)
        fun deleting(state: State, texts: Texts) = Deleting(state = state, texts = texts)
        fun deleted(state: State, texts: Texts) = Deleted(state = state, texts = texts)

        fun failed(failure: Either<List<Warning>, Throwable>, isSignedIn: Boolean): ViewModel =
            when (failure) {
                is Either.Left -> ValidationError(warnings = failure.value)
                is Either.Right -> Failed(state = state, texts = texts, failure = failure(failure, isSignedIn))
            }

    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result, store.isSignedIn))

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        //if (!store.isAdmin) return scene.failed(Either.Right(TechlaError.Unauthorized("Must be admin to manage users")))

        return store.listProfiles().map { (actions, profiles) ->
            val action = Store.Action.ListProfiles(profiles = profiles)
            sceneOf<ViewModel>(viewModel.ready(viewModel.state.copy(profile = null), viewModel.texts), actions + action)
        }.failed { scene.failed(result = it) }
    }

    fun creating(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return sceneOf(viewModel.creating(viewModel.state.copy(profile = null), viewModel.texts), emptyList())
    }

    fun edit(scene: Scene.Input<ViewModel>, profile: Profile): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val state = viewModel.state.copy(profile = profile)
        return sceneOf(viewModel.edit(state = state, viewModel.texts), emptyList())
    }

    fun deleting(scene: Scene.Input<ViewModel>, profile: Profile): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val state = viewModel.state.copy(profile = profile)
        return sceneOf(viewModel.deleting(state = state, viewModel.texts), emptyList())
    }

    suspend fun create(
        scene: Scene.Input<ViewModel>, email: String, company: String, name: String
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (email.isBlank()) return scene.failed(Either.Right(TechlaError.BadRequest("Email cannot be empty")))
        if (name.isBlank()) return scene.failed(Either.Right(TechlaError.BadRequest("Name cannot be empty")))
        if (company.isBlank()) return scene.failed(Either.Right(TechlaError.BadRequest("Company cannot be empty")))

        val create = Profile.Create(
            email = email,
            firstName = company,
            lastName = name,
            govId = null,
            workspaces = null,
            access = null,
        )

        return store.createProfile(create = create).map { (actions, _) ->
            sceneOf<ViewModel>(viewModel.success(viewModel.state, viewModel.texts), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun edit(
        scene: Scene.Input<ViewModel>, email: String, company: String, name: String
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val emailValue = email.ifBlank { viewModel.state.profile?.email }
        val nameValue = name.ifBlank { viewModel.state.profile?.lastName }
        val companyValue = company.ifBlank { viewModel.state.profile?.firstName }

        if (emailValue?.isBlank() == true) return scene.failed(Either.Right(TechlaError.BadRequest("Email cannot be empty")))
        if (nameValue?.isBlank() == true) return scene.failed(Either.Right(TechlaError.BadRequest("Name cannot be empty")))
        if (companyValue?.isBlank() == true) return scene.failed(Either.Right(TechlaError.BadRequest("Company cannot be empty")))

        val edit = Profile.Edit(
            email = modifiedOf(emailValue),
            firstName = modifiedOf(companyValue),
            lastName = modifiedOf(nameValue),
        )

        return store.editProfile(id = viewModel.state.profile?.index?.id!!, edit = edit).map { (actions, _) ->
            sceneOf<ViewModel>(viewModel.success(viewModel.state, viewModel.texts), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun delete(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.deleteProfile(viewModel.state.profile?.index?.id!!).map { (actions, _) ->
            sceneOf<ViewModel>(viewModel.deleted(viewModel.state.copy(profile = null), viewModel.texts), actions)
        }.failed { scene.failed(result = it) }
    }
}
