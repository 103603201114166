package services

import support.ActionOutcome
import support.Store
import techla.base.*
import techla.reservation.Reservation
import techla.reservation.ReservationAPI
import techla.reservation.ReservationResource
import techla.reservation.Resource

suspend fun Store.findReservations(): ActionOutcome<List<Reservation>> = withUserToken { updated ->
    val api = updated.reservationAPI
    api.token = updated.userToken

    measureAPI(ReservationResource.ListReservations(null, null), api) {
        api.findReservations(Reservation.Filter.ForDate(date)).zip(api.findReservations(Reservation.Filter.Future))
            .map { (it.first + it.second).distinct() }.onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.listResources(): ActionOutcome<List<Resource>> = withUserToken { updated ->
    val api = updated.reservationAPI
    api.token = updated.userToken
    measureAPI(ReservationResource.ListResources, api) {
        api.listResources().onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.createReservation(create: Reservation.Create): ActionOutcome<List<Reservation>> =
    withUserToken { updated ->
        val api = updated.reservationAPI
        api.token = updated.userToken
        measureAPI(ReservationResource.CreateReservation(create), api) {
            api.createReservation(create).flatMap { api.findReservations(Reservation.Filter.ForDate(date)) }.onNotSuccess { techla_log("WARN: $it") }
        }
    }

suspend fun Store.deleteReservation(id: Identifier<Reservation>): ActionOutcome<List<Reservation>> =
    withUserToken { updated ->
        val api = updated.reservationAPI
        api.token = updated.userToken

        measureAPI(ReservationResource.DeleteReservation(id), api) {
            api.deleteReservation(id).flatMap { api.findReservations(Reservation.Filter.ForDate(date)) }.onNotSuccess { techla_log("WARN: $it") }
        }
    }

val Store.reservationAPI
    get() = ReservationAPI(httpClient).also { api ->
        api.host = if (deployment.isSandbox) ReservationAPI.sandbox else ReservationAPI.shared
    }