package techla.base

import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.Serializable

@Serializable
data class Warning(val code: String, val message: String) {
    companion object {
        fun fromHeader(header: String): List<Warning> =
            header.split(",").map { element ->
                val list = element.trim().split(";")
                when (list.count()) {
                    0 -> Warning(code = "", message = decode(element))
                    1 -> Warning(code = "", message = decode(list[0]))
                    else -> Warning(code = list[0], message = decode(list[1]))
                }
            }

        fun encode(s: String) =
            s
                .replace(",", "%2C")
                .replace(";", "%3B")
                .replace(" ", "%20")

        fun decode(s: String) =
            s
                .replace("%2C", ",")
                .replace("%3B", ";")
                .replace("%20", " ")
    }
}

fun List<Warning>.toHeader() = joinToString(", ") { "${it.code};${Warning.encode(it.message)}" }

@Serializable
data class WarningV1(val message: String) {
    val warning: Warning get() = Warning(code = "", message = message)
}

sealed class Outcome<Output> {
    data class Successful<Output>(val output: Output, val warnings: List<Warning>) : Outcome<Output>()
    data class Invalid<Output>(val warnings: List<Warning>) : Outcome<Output>()
    data class Failed<Output>(val exception: Throwable) : Outcome<Output>()

    companion object;

    fun outputOrNull(): Output? {
        return when (this) {
            is Successful -> output
            is Invalid -> null
            is Failed -> null
        }
    }

    fun warningsOrNull(): List<Warning>? {
        return when (this) {
            is Successful -> warnings
            is Invalid -> warnings
            is Failed -> null
        }
    }

    fun exceptionOrNull(): Throwable? {
        return when (this) {
            is Successful -> null
            is Invalid -> null
            is Failed -> exception
        }
    }

    override fun toString(): String {
        return when (this) {
            is Successful -> "Outcome.Successful"
            is Invalid -> "Outcome.Invalid"
            is Failed -> "Outcome.Failed"
        }
    }
}

operator fun <Output> Outcome<Output>.component1() = outputOrNull()
operator fun <Output> Outcome<Output>.component2() = exceptionOrNull()
operator fun <Output> Outcome<Output>.component3() = warningsOrNull()

fun <Output> successfulOf(output: Output): Outcome<Output> = Outcome.Successful(output, emptyList())
fun <Output> successfulOf(output: Output, warnings: List<Warning>): Outcome<Output> = Outcome.Successful(output, warnings)
fun <Output> invalidOf(warning: Warning): Outcome<Output> = invalidOf(listOf(warning))
fun <Output> invalidOf(warnings: List<Warning>): Outcome<Output> = Outcome.Invalid(warnings)
fun <Output> failedOf(exception: Throwable): Outcome<Output> = Outcome.Failed(exception)

suspend fun <Output> Outcome.Companion.runCatching(block: suspend Outcome.Companion.() -> Output): Outcome<Output> {
    return try {
        successfulOf(block(this))
    } catch (e: Throwable) {
        failedOf(e)
    }
}

inline fun <Source, Target> Outcome<Source>.map(f: (Source) -> Target): Outcome<Target> {
    return when (this) {
        is Outcome.Successful -> Outcome.Successful(f(output), warnings)
        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

inline fun <Source, Target> Outcome<Source>.flatMap(f: (Source) -> Outcome<Target>): Outcome<Target> {
    return when (this) {
        is Outcome.Successful -> f(output)
        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

inline fun <Source, Target> Outcome<Source>.mapWithWarnings(f: (Source, List<Warning>) -> Target): Outcome<Target> {
    return when (this) {
        is Outcome.Successful -> Outcome.Successful(f(output, warnings), warnings)
        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

inline fun <Source, Target> Outcome<Source>.flatMapWithWarnings(f: (Source, List<Warning>) -> Outcome<Target>): Outcome<Target> {
    return when (this) {
        is Outcome.Successful -> f(output, warnings)
        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

inline fun <Result, Output> Outcome<Output>.fold(
    onSuccess: (output: Output) -> Result,
    onInvalid: (warnings: List<Warning>) -> Result,
    onFailure: (exception: Throwable) -> Result
): Result {
    return when (this) {
        is Outcome.Successful -> onSuccess(output)
        is Outcome.Invalid -> onInvalid(warnings)
        is Outcome.Failed -> onFailure(exception)
    }
}

inline fun <Result, Output> Outcome<Output>.fold(
    onSuccess: (output: Output) -> Result,
    onNotSuccess: (Either<List<Warning>, Throwable>) -> Result
): Result {
    return when (this) {
        is Outcome.Successful -> onSuccess(output)
        is Outcome.Invalid -> onNotSuccess(leftOf2(warnings))
        is Outcome.Failed -> onNotSuccess(rightOf2(exception))
    }
}

inline fun <Output> Outcome<Output>.onInvalid(f: (List<Warning>) -> Unit): Outcome<Output> {
    return when (this) {
        is Outcome.Successful, is Outcome.Failed -> this
        is Outcome.Invalid -> {
            f(warnings)
            this
        }
    }
}

inline fun <Output> Outcome<Output>.onNotInvalid(f: (Either<Output, Throwable>) -> Unit): Outcome<Output> {
    return when (this) {
        is Outcome.Invalid -> this
        is Outcome.Successful -> {
            f(leftOf2(output))
            this
        }

        is Outcome.Failed -> {
            f(rightOf2(exception))
            this
        }
    }
}

inline fun <Output> Outcome<Output>.onFailure(f: (Throwable) -> Unit): Outcome<Output> {
    return when (this) {
        is Outcome.Successful, is Outcome.Invalid -> this
        is Outcome.Failed -> {
            f(exception)
            this
        }
    }
}

inline fun <Output> Outcome<Output>.onNotFailure(f: (Either<Output, List<Warning>>) -> Unit): Outcome<Output> {
    return when (this) {
        is Outcome.Failed -> this
        is Outcome.Successful -> {
            f(leftOf2(output))
            this
        }

        is Outcome.Invalid -> {
            f(rightOf2(warnings))
            this
        }
    }
}

inline fun <Output> Outcome<Output>.onSuccess(f: (Output) -> Unit): Outcome<Output> {
    return when (this) {
        is Outcome.Failed, is Outcome.Invalid -> this
        is Outcome.Successful -> {
            f(output)
            this
        }
    }
}

inline fun <Output> Outcome<Output>.onNotSuccess(f: (Either<List<Warning>, Throwable>) -> Unit): Outcome<Output> {
    return when (this) {
        is Outcome.Successful -> this
        is Outcome.Invalid -> {
            f(leftOf2(warnings))
            this
        }

        is Outcome.Failed -> {
            f(rightOf2(exception))
            this
        }
    }
}

inline fun <Output> Outcome<Output>.always(f: (Outcome<Output>) -> Unit): Outcome<Output> {
    f(this)
    return this
}

fun <Output> List<Outcome<Output>>.all(predicate: (Output) -> Boolean = { true }): Outcome<List<Output>> =
    successfulOf(mapNotNull {
        when (it) {
            is Outcome.Failed -> return failedOf(it.exception)
            is Outcome.Invalid -> return failedOf(TechlaError.PreconditionFailed(""))
            is Outcome.Successful -> {
                if (!predicate(it.output)) return failedOf(TechlaError.PreconditionFailed(""))
                it.output
            }
        }
    })

@Suppress("NOTHING_TO_INLINE")
inline fun <A, B> Outcome<A>.zip(b: Outcome<B>): Outcome<Pair<A, B>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> Outcome.Successful(tupleOf(output, b.output), warnings + b.warnings)
                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B> successfulOf(first: A, second: B) =
    Outcome.Successful(tupleOf(first, second), emptyList())

@Suppress("NOTHING_TO_INLINE")
inline fun <A, B, C> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>): Outcome<Triple<A, B, C>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> Outcome.Successful(tupleOf(output, b.output, c.output), warnings + b.warnings + c.warnings)
                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C> successfulOf(a: A, b: B, c: C) =
    Outcome.Successful(tupleOf(a, b, c), emptyList())

inline fun <Source, Target> Outcome<List<Source>>.mapEach(f: (Source) -> Target): Outcome<List<Target>> =
    map { it.map(f) }

inline fun <Source, Target> Outcome<PageContent<Source>>.mapContent(f: (Source) -> Target): Outcome<PageContent<Target>> =
    map { it.map(f) }

fun <Source, Target> Outcome<PageContent<Source>>.flatMapContent(f: (Source) -> Outcome<Target>): Outcome<PageContent<Target>> =
    flatMap { page -> page.contents.map(f).all().map { PageContent(it, page.index, page.items, page.pages) } }

suspend fun <Source, Target> Outcome<PageContent<Source>>.flatMapContentParallel(f: suspend (Source) -> Outcome<Target>): Outcome<PageContent<Target>> =
    coroutineScope {
        flatMap { page -> page.contents.map { async { f(it) } }.awaitAll().all().map { PageContent(it, page.index, page.items, page.pages) } }
    }

suspend fun <Source, Target> Outcome<Source>.mapParallel(f: suspend (Source) -> Target): Outcome<Target> {
    return when (this) {
        is Outcome.Successful -> Outcome.Successful(f(output), warnings)
        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

suspend fun <Source, Target> Outcome<Source>.flatMapParallel(f: suspend (Source) -> Outcome<Target>): Outcome<Target> {
    return when (this) {
        is Outcome.Successful -> f(output)
        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

suspend fun <Source, Target> Outcome<List<Source>>.mapEachParallel(f: suspend (Source) -> Target): Outcome<List<Target>> {
    return this.mapParallel {
        coroutineScope {
            it.map { async { f(it) } }.awaitAll()
        }
    }
}

suspend fun <Source, Target> Outcome<PageContent<Source>>.mapContentParallel(f: suspend (Source) -> Target): Outcome<PageContent<Target>> {
    return this.mapParallel { it.mapParallel(f) }
}

inline fun <Tag, R : Comparable<R>> Outcome<PageContent<Tag>>.sortedBy(crossinline selector: (Tag) -> R?): Outcome<PageContent<Tag>> {
    return this.map { it.sortedBy(selector) }
}

fun <Output> Outcome<String>.decode(deserializer: DeserializationStrategy<Output>): Outcome<Output> {
    return this
        .map {
            TechlaJson.decodeFromString(deserializer, it)
        }
}

fun <A, B, C, D> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>): Outcome<Quadruple<A, B, C, D>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful ->
                                    Outcome.Successful(tupleOf(output, b.output, c.output, d.output), warnings + b.warnings + c.warnings + d.warnings)

                                is Outcome.Invalid -> Outcome.Invalid(d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D> successfulOf(a: A, b: B, c: C, d: D) =
    Outcome.Successful(tupleOf(a, b, c, d), emptyList())

fun <A, B, C, D, E> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>, e: Outcome<E>): Outcome<Quintuple<A, B, C, D, E>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful -> {
                                    return when (e) {
                                        is Outcome.Successful ->
                                            Outcome.Successful(
                                                tupleOf(output, b.output, c.output, d.output, e.output), warnings + b.warnings + c.warnings + d.warnings + e.warnings
                                            )

                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings)
                                        is Outcome.Failed -> Outcome.Failed(e.exception)
                                    }
                                }

                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D, E> successfulOf(a: A, b: B, c: C, d: D, e: E) =
    Outcome.Successful(tupleOf(a, b, c, d, e), emptyList())

fun <A, B, C, D, E, F> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>, e: Outcome<E>, f: Outcome<F>): Outcome<Sextuple<A, B, C, D, E, F>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful -> {
                                    return when (e) {
                                        is Outcome.Successful ->
                                            return when (f) {
                                                is Outcome.Successful ->
                                                    Outcome.Successful(
                                                        tupleOf(output, b.output, c.output, d.output, e.output, f.output), warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings
                                                    )

                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings)
                                                is Outcome.Failed -> Outcome.Failed(f.exception)
                                            }

                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings)
                                        is Outcome.Failed -> Outcome.Failed(e.exception)
                                    }
                                }

                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D, E, F> successfulOf(a: A, b: B, c: C, d: D, e: E, f: F) =
    Outcome.Successful(tupleOf(a, b, c, d, e, f), emptyList())

fun <A, B, C, D, E, F, G> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>, e: Outcome<E>, f: Outcome<F>, g: Outcome<G>): Outcome<Septuple<A, B, C, D, E, F, G>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful -> {
                                    return when (e) {
                                        is Outcome.Successful ->
                                            return when (f) {
                                                is Outcome.Successful ->
                                                    return when (g) {
                                                        is Outcome.Successful ->
                                                            Outcome.Successful(
                                                                tupleOf(output, b.output, c.output, d.output, e.output, f.output, g.output), warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings
                                                            )

                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings)
                                                        is Outcome.Failed -> Outcome.Failed(g.exception)
                                                    }

                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings)
                                                is Outcome.Failed -> Outcome.Failed(f.exception)
                                            }

                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings)
                                        is Outcome.Failed -> Outcome.Failed(e.exception)
                                    }
                                }

                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D, E, F, G> successfulOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G) =
    Outcome.Successful(tupleOf(a, b, c, d, e, f, g), emptyList())

fun <A, B, C, D, E, F, G, H> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>, e: Outcome<E>, f: Outcome<F>, g: Outcome<G>, h: Outcome<H>): Outcome<Octuple<A, B, C, D, E, F, G, H>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful -> {
                                    return when (e) {
                                        is Outcome.Successful ->
                                            return when (f) {
                                                is Outcome.Successful ->
                                                    return when (g) {
                                                        is Outcome.Successful ->
                                                            return when (h) {
                                                                is Outcome.Successful ->
                                                                    Outcome.Successful(
                                                                        tupleOf(output, b.output, c.output, d.output, e.output, f.output, g.output, h.output), warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings
                                                                    )

                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings)
                                                                is Outcome.Failed -> Outcome.Failed(h.exception)
                                                            }

                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings)
                                                        is Outcome.Failed -> Outcome.Failed(g.exception)
                                                    }

                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings)
                                                is Outcome.Failed -> Outcome.Failed(f.exception)
                                            }

                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings)
                                        is Outcome.Failed -> Outcome.Failed(e.exception)
                                    }
                                }

                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D, E, F, G, H> successfulOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H) =
    Outcome.Successful(tupleOf(a, b, c, d, e, f, g, h), emptyList())

fun <A, B, C, D, E, F, G, H, I> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>, e: Outcome<E>, f: Outcome<F>, g: Outcome<G>, h: Outcome<H>, i: Outcome<I>): Outcome<Nonuple<A, B, C, D, E, F, G, H, I>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful -> {
                                    return when (e) {
                                        is Outcome.Successful ->
                                            return when (f) {
                                                is Outcome.Successful ->
                                                    return when (g) {
                                                        is Outcome.Successful ->
                                                            return when (h) {
                                                                is Outcome.Successful ->
                                                                    return when (i) {
                                                                        is Outcome.Successful ->
                                                                            Outcome.Successful(
                                                                                tupleOf(output, b.output, c.output, d.output, e.output, f.output, g.output, h.output, i.output), warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings
                                                                            )

                                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings)
                                                                        is Outcome.Failed -> Outcome.Failed(i.exception)
                                                                    }

                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings)
                                                                is Outcome.Failed -> Outcome.Failed(h.exception)
                                                            }

                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings)
                                                        is Outcome.Failed -> Outcome.Failed(g.exception)
                                                    }

                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings)
                                                is Outcome.Failed -> Outcome.Failed(f.exception)
                                            }

                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings)
                                        is Outcome.Failed -> Outcome.Failed(e.exception)
                                    }
                                }

                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D, E, F, G, H, I> successfulOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I) =
    Outcome.Successful(tupleOf(a, b, c, d, e, f, g, h, i), emptyList())

fun <A, B, C, D, E, F, G, H, I, J> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>, e: Outcome<E>, f: Outcome<F>, g: Outcome<G>, h: Outcome<H>, i: Outcome<I>, j: Outcome<J>): Outcome<Decuple<A, B, C, D, E, F, G, H, I, J>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful -> {
                                    return when (e) {
                                        is Outcome.Successful ->
                                            return when (f) {
                                                is Outcome.Successful ->
                                                    return when (g) {
                                                        is Outcome.Successful ->
                                                            return when (h) {
                                                                is Outcome.Successful ->
                                                                    return when (i) {
                                                                        is Outcome.Successful ->
                                                                            return when (j) {
                                                                                is Outcome.Successful ->
                                                                                    Outcome.Successful(
                                                                                        tupleOf(output, b.output, c.output, d.output, e.output, f.output, g.output, h.output, i.output, j.output), warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings
                                                                                    )

                                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings)
                                                                                is Outcome.Failed -> Outcome.Failed(j.exception)
                                                                            }

                                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings)
                                                                        is Outcome.Failed -> Outcome.Failed(i.exception)
                                                                    }

                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings)
                                                                is Outcome.Failed -> Outcome.Failed(h.exception)
                                                            }

                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings)
                                                        is Outcome.Failed -> Outcome.Failed(g.exception)
                                                    }

                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings)
                                                is Outcome.Failed -> Outcome.Failed(f.exception)
                                            }

                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings)
                                        is Outcome.Failed -> Outcome.Failed(e.exception)
                                    }
                                }

                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D, E, F, G, H, I, J> successfulOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I, j: J) =
    Outcome.Successful(tupleOf(a, b, c, d, e, f, g, h, i, j), emptyList())

fun <A, B, C, D, E, F, G, H, I, J, K> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>, e: Outcome<E>, f: Outcome<F>, g: Outcome<G>, h: Outcome<H>, i: Outcome<I>, j: Outcome<J>, k: Outcome<K>): Outcome<Undecuple<A, B, C, D, E, F, G, H, I, J, K>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful -> {
                                    return when (e) {
                                        is Outcome.Successful ->
                                            return when (f) {
                                                is Outcome.Successful ->
                                                    return when (g) {
                                                        is Outcome.Successful ->
                                                            return when (h) {
                                                                is Outcome.Successful ->
                                                                    return when (i) {
                                                                        is Outcome.Successful ->
                                                                            return when (j) {
                                                                                is Outcome.Successful ->
                                                                                    return when (k) {
                                                                                        is Outcome.Successful ->
                                                                                            Outcome.Successful(
                                                                                                tupleOf(output, b.output, c.output, d.output, e.output, f.output, g.output, h.output, i.output, j.output, k.output), warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings + k.warnings
                                                                                            )

                                                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings + k.warnings)
                                                                                        is Outcome.Failed -> Outcome.Failed(k.exception)
                                                                                    }

                                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings)
                                                                                is Outcome.Failed -> Outcome.Failed(j.exception)
                                                                            }

                                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings)
                                                                        is Outcome.Failed -> Outcome.Failed(i.exception)
                                                                    }

                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings)
                                                                is Outcome.Failed -> Outcome.Failed(h.exception)
                                                            }

                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings)
                                                        is Outcome.Failed -> Outcome.Failed(g.exception)
                                                    }

                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings)
                                                is Outcome.Failed -> Outcome.Failed(f.exception)
                                            }

                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings)
                                        is Outcome.Failed -> Outcome.Failed(e.exception)
                                    }
                                }

                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D, E, F, G, H, I, J, K> successfulOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I, j: J, k: K) =
    Outcome.Successful(tupleOf(a, b, c, d, e, f, g, h, i, j, k), emptyList())

fun <A, B, C, D, E, F, G, H, I, J, K, L> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>, e: Outcome<E>, f: Outcome<F>, g: Outcome<G>, h: Outcome<H>, i: Outcome<I>, j: Outcome<J>, k: Outcome<K>, l: Outcome<L>): Outcome<Duodecuple<A, B, C, D, E, F, G, H, I, J, K, L>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful -> {
                                    return when (e) {
                                        is Outcome.Successful ->
                                            return when (f) {
                                                is Outcome.Successful ->
                                                    return when (g) {
                                                        is Outcome.Successful ->
                                                            return when (h) {
                                                                is Outcome.Successful ->
                                                                    return when (i) {
                                                                        is Outcome.Successful ->
                                                                            return when (j) {
                                                                                is Outcome.Successful ->
                                                                                    return when (k) {
                                                                                        is Outcome.Successful ->
                                                                                            return when (l) {
                                                                                                is Outcome.Successful ->
                                                                                                    Outcome.Successful(
                                                                                                        tupleOf(output, b.output, c.output, d.output, e.output, f.output, g.output, h.output, i.output, j.output, k.output, l.output), warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings + k.warnings + l.warnings
                                                                                                    )

                                                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings + k.warnings + l.warnings)
                                                                                                is Outcome.Failed -> Outcome.Failed(l.exception)
                                                                                            }

                                                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings + k.warnings)
                                                                                        is Outcome.Failed -> Outcome.Failed(k.exception)
                                                                                    }

                                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings)
                                                                                is Outcome.Failed -> Outcome.Failed(j.exception)
                                                                            }

                                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings)
                                                                        is Outcome.Failed -> Outcome.Failed(i.exception)
                                                                    }

                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings)
                                                                is Outcome.Failed -> Outcome.Failed(h.exception)
                                                            }

                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings)
                                                        is Outcome.Failed -> Outcome.Failed(g.exception)
                                                    }

                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings)
                                                is Outcome.Failed -> Outcome.Failed(f.exception)
                                            }

                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings)
                                        is Outcome.Failed -> Outcome.Failed(e.exception)
                                    }
                                }

                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D, E, F, G, H, I, J, K, L> successfulOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I, j: J, k: K, l: L) =
    Outcome.Successful(tupleOf(a, b, c, d, e, f, g, h, i, j, k, l), emptyList())

fun <A, B, C, D, E, F, G, H, I, J, K, L, M> Outcome<A>.zip(b: Outcome<B>, c: Outcome<C>, d: Outcome<D>, e: Outcome<E>, f: Outcome<F>, g: Outcome<G>, h: Outcome<H>, i: Outcome<I>, j: Outcome<J>, k: Outcome<K>, l: Outcome<L>, m: Outcome<M>): Outcome<Tredecuple<A, B, C, D, E, F, G, H, I, J, K, L, M>> {
    return when (this) {
        is Outcome.Successful -> {
            return when (b) {
                is Outcome.Successful -> {
                    return when (c) {
                        is Outcome.Successful -> {
                            return when (d) {
                                is Outcome.Successful -> {
                                    return when (e) {
                                        is Outcome.Successful ->
                                            return when (f) {
                                                is Outcome.Successful ->
                                                    return when (g) {
                                                        is Outcome.Successful ->
                                                            return when (h) {
                                                                is Outcome.Successful ->
                                                                    return when (i) {
                                                                        is Outcome.Successful ->
                                                                            return when (j) {
                                                                                is Outcome.Successful ->
                                                                                    return when (k) {
                                                                                        is Outcome.Successful ->
                                                                                            return when (l) {
                                                                                                is Outcome.Successful ->
                                                                                                    return when (m) {
                                                                                                        is Outcome.Successful ->
                                                                                                            Outcome.Successful(
                                                                                                                tupleOf(output, b.output, c.output, d.output, e.output, f.output, g.output, h.output, i.output, j.output, k.output, l.output, m.output), warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings + k.warnings + l.warnings + m.warnings
                                                                                                            )

                                                                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings + k.warnings + l.warnings + m.warnings)
                                                                                                        is Outcome.Failed -> Outcome.Failed(m.exception)
                                                                                                    }

                                                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings + k.warnings + l.warnings)
                                                                                                is Outcome.Failed -> Outcome.Failed(l.exception)
                                                                                            }

                                                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings + k.warnings)
                                                                                        is Outcome.Failed -> Outcome.Failed(k.exception)
                                                                                    }

                                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings + j.warnings)
                                                                                is Outcome.Failed -> Outcome.Failed(j.exception)
                                                                            }

                                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings + i.warnings)
                                                                        is Outcome.Failed -> Outcome.Failed(i.exception)
                                                                    }

                                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings + h.warnings)
                                                                is Outcome.Failed -> Outcome.Failed(h.exception)
                                                            }

                                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings + g.warnings)
                                                        is Outcome.Failed -> Outcome.Failed(g.exception)
                                                    }

                                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings + f.warnings)
                                                is Outcome.Failed -> Outcome.Failed(f.exception)
                                            }

                                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings + e.warnings)
                                        is Outcome.Failed -> Outcome.Failed(e.exception)
                                    }
                                }

                                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings + d.warnings)
                                is Outcome.Failed -> Outcome.Failed(d.exception)
                            }
                        }

                        is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings + c.warnings)
                        is Outcome.Failed -> Outcome.Failed(c.exception)
                    }
                }

                is Outcome.Invalid -> Outcome.Invalid(warnings + b.warnings)
                is Outcome.Failed -> Outcome.Failed(b.exception)
            }
        }

        is Outcome.Invalid -> Outcome.Invalid(warnings)
        is Outcome.Failed -> Outcome.Failed(exception)
    }
}

fun <A, B, C, D, E, F, G, H, I, J, K, L, M> successfulOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I, j: J, k: K, l: L, m: M) =
    Outcome.Successful(tupleOf(a, b, c, d, e, f, g, h, i, j, k, l, m), emptyList())
