package techla.guard

import kotlinx.serialization.Serializable
import techla.base.Identifier
import techla.base.Key

@Serializable
data class Me(
    val profileId: Identifier<Profile>,

    val govId: String?,
    val firstName: String?,
    val lastName: String?,
    val email: String?,
    val phone: String?,

    val access: List<Access>,
    val workspaces: List<Key<Workspace>>,
) {
    val roles get() =
        access.map { it.role }.distinct()

    val services get() =
        access.map { it.service }
}