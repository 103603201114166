package techla.guard

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import techla.base.ListStringSerializer
import techla.base.TechlaError

@Serializable(with = RoleSerializer::class)
sealed class Role {
    data object Unknown: Role()

    data object Anonymous: Role()
    data object Guest: Role()
    data object User: Role()
    data object Admin: Role()
    data object System: Role()
    data object External: Role()

    val rawValue: String get() =
        RoleSerializer.serialize(this).first()

    companion object
}

object RoleSerializer: KSerializer<Role> {
    override val descriptor: SerialDescriptor =
        ListStringSerializer.descriptor

    override fun serialize(encoder: Encoder, value: Role) =
        encoder.encodeSerializableValue(ListStringSerializer, serialize(value))

    override fun deserialize(decoder: Decoder): Role =
        deserialize(decoder.decodeSerializableValue(ListStringSerializer))

    private const val ADMIN = "admin"
    private const val GUEST = "guest"
    private const val USER = "user"
    private const val ANONYMOUS = "anonymous"
    private const val SYSTEM = "system"
    private const val EXTERNAL = "external"

    fun serialize(obj: Role): List<String> {
        return when (obj) {
            is Role.Unknown -> throw TechlaError.PreconditionFailed("Can't encode Role.Unknown")
            is Role.Admin -> listOf(ADMIN)
            is Role.User -> listOf(USER)
            is Role.Guest -> listOf(GUEST)
            is Role.Anonymous -> listOf(ANONYMOUS)
            is Role.System -> listOf(SYSTEM)
            is Role.External -> listOf(EXTERNAL)
        }
    }

    fun deserialize(data: List<String>): Role {
        return when (data.first()) {
            ADMIN -> Role.Admin
            USER -> Role.User
            GUEST -> Role.Guest
            ANONYMOUS -> Role.Anonymous
            SYSTEM -> Role.System
            EXTERNAL -> Role.External
            else -> Role.Unknown
        }
    }
}