@file:UseSerializers(UrlSerializer::class)
package techla.base

import io.ktor.http.Url
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers

// typealias Relation = Tagged<Link, String>
typealias Relation = String

// - As of Kotlin 1.3.50 the compiler can't handle that rel is declared using the typealias
//  Relation. The compiler will crash with an out-of-bounds exception. When this bug i resolved
//  change to val rel: Relation instead
// - As of 1.4.0 we're trying to use Relation instead of Tagged<Link, String>
// - As of 1.5.30 Tagged has stopped working at all, use String instead
@Serializable
data class Link(val rel: Relation, val href: Url) {
    companion object {
        val SELF = "self"
        val ATTACHMENT = "attachment"
    }
}
