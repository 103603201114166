package techla.base

import kotlin.time.Duration

data class CacheKey(val namespace: Cache.Namespace, val key: String)

data class Cache(
    val storage: CacheStorage = InMemoryCacheStorage(),
    val settings: Map<Namespace, Settings>
) {
    sealed class Group {
        data object Bio : Group()
        data object Block : Group()
        data object Catalog : Group()
        data object Media : Group()
        data object Profile : Group()
        data object Prompt : Group()
        data object Resource : Group()
        data object Tag : Group()
        data object Venture : Group()
    }

    sealed class Namespace(val namespace: String, val group: Group) {
        data object Bio: Namespace("bio.find-by-id", Group.Bio)
        data object Bios: Namespace("bios.batch", Group.Bio)
        data object FindCatalogs: Namespace("catalogs.find", Group.Catalog)
        data object FindMedias: Namespace("medias.find", Group.Media)
        data object FindPrompts: Namespace("prompts.find", Group.Prompt)
        data object FindTags: Namespace("tags.find", Group.Tag)
        data object FindVentures : Namespace("ventures.find", Group.Venture)
        data object GetResource : Namespace("resources.get", Group.Resource)
        data object GetResources : Namespace("resources.batch", Group.Resource)
        data object GetVentures : Namespace("ventures.batch", Group.Venture)
        data object ListBlocks: Namespace("blocks.list", Group.Block)
        data object ListProfiles : Namespace("profiles.list-all", Group.Profile)
    }

    data class Settings(val timeToLive: Duration)

    fun clear() = storage.clear()
    fun clear(group: Cache.Group) = storage.clear(group = group)
}
