package techla.base

import io.ktor.http.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.json.*

val TechlaJson =
    Json {
        ignoreUnknownKeys = true
        encodeDefaults = true
        classDiscriminator = "_discriminator"
    }

fun JsonElement?.parseString(): String? {
    return this?.jsonPrimitive?.content.let { if (it == null || "null" == it) null else it }
}

fun JsonElement?.parseBoolean(): Boolean? {
    return parseString()
        ?.toBoolean()
}

fun JsonElement?.parseDate(): Date? {
    return parseString()
        ?.let { DateSerializer.deserialize(it) }
}

fun JsonElement?.parseDouble(): Double? {
    return parseString()
        ?.toDoubleOrNull()
}

fun JsonElement?.parseFloat(): Float? {
    return parseString()
        ?.toFloatOrNull()
}

fun JsonElement?.parseInteger(): Int? {
    return parseString()
        ?.toIntOrNull()
}

fun JsonElement?.parseUrl(): Url? {
    return parseString()
        ?.let { UrlSerializer.deserialize(it) }
}

fun <T> JsonElement?.parseIdentifier(): Identifier<T>? {
    return parseString()
        ?.let { Identifier(it) }
}

fun <T> JsonElement?.parseKey(): Key<T>? {
    return parseString()
        ?.let { Key(it) }
}

fun <T> JsonElement?.parseObject(serializer: KSerializer<T>): T? {
    return this?.let { TechlaJson.decodeFromJsonElement(serializer, it) }
}

fun <T> JsonElement?.parseList(serializer: KSerializer<T>): List<T>? {
    return this?.let { TechlaJson.decodeFromJsonElement(ListSerializer(serializer), it) }
}

fun <K, V> JsonElement?.parseMap(keySerializer: KSerializer<K>, valueSerializer: KSerializer<V>): Map<K, V>? {
    return this?.let { TechlaJson.decodeFromJsonElement(MapSerializer(keySerializer, valueSerializer), it) }
}

fun <T> JsonObjectBuilder.put(
    key: String,
    value: Identifier<T>?
): JsonElement? {
    return value?.let { put(key, value.rawValue) }
}

fun <T> JsonObjectBuilder.put(
    key: String,
    value: Key<T>?
): JsonElement? {
    return value?.let { put(key, value.rawValue) }
}

fun JsonObjectBuilder.put(
    key: String,
    value: Date?
): JsonElement? {
    return value?.let { put(key, DateSerializer.serialize(value)) }
}

fun JsonObjectBuilder.put(
    key: String,
    value: Url?
): JsonElement? {
    return value?.let { put(key, UrlSerializer.serialize(value)) }
}

fun <T> JsonObjectBuilder.putObject(
    key: String,
    value: T?,
    serializer: KSerializer<T>,
): JsonElement? {
    return value?.let { put(key, value.encodeObject(serializer)) }
}

fun <T> JsonObjectBuilder.putList(
    key: String,
    value: List<T>?,
    serializer: KSerializer<T>,
): JsonElement? {
    return value?.let { put(key, value.encodeList(serializer)) }
}

fun <K, V> JsonObjectBuilder.putMap(
    key: String,
    value: Map<K, V>?,
    keySerializer: KSerializer<K>,
    valueSerializer: KSerializer<V>,
): JsonElement? {
    return value?.let { put(key, value.encodeMap(keySerializer, valueSerializer)) }
}

fun <T> T.encodeObject(serializer: KSerializer<T>): JsonElement {
    return TechlaJson.encodeToJsonElement(serializer, this)
}

fun <T> List<T>.encodeList(serializer: KSerializer<T>): JsonElement {
    return TechlaJson.encodeToJsonElement(ListSerializer(serializer), this)
}

fun <K, V> Map<K, V>.encodeMap(keySerializer: KSerializer<K>, valueSerializer: KSerializer<V>): JsonElement {
    return TechlaJson.encodeToJsonElement(MapSerializer(keySerializer, valueSerializer), this)
}
