package techla.base

import kotlinx.datetime.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlin.jvm.JvmName
import kotlin.native.concurrent.ThreadLocal

@Serializable(with = DateSerializer::class)
data class Date(
    val dateTime: LocalDateTime = now().dateTime
) : Comparable<Date> {
    @ThreadLocal
    companion object {
        // LocalDateTime.MIN and MAX are currently internal
        private const val YEAR_MIN = -999_999
        private const val YEAR_MAX = 999_999

        var now: () -> Date = { Date(Clock.System.now().toLocalDateTime(TimeZone.UTC)) }

        val distantPast =
            dateAt(YEAR_MIN, 1, 1, 0, 0, 0)
        val distantFuture =
            dateAt(YEAR_MAX, 12, 31, 23, 59, 59)

        fun dateAt(year: Int, month: Int, day: Int, hour: Int = 0, minute: Int = 0, second: Int = 0) =
            Date(LocalDateTime(year = year, monthNumber = month, dayOfMonth = day, hour = hour, minute = minute, second = second))
    }

    fun hasPassed(): Boolean =
        dateTime < now().dateTime

    override fun compareTo(other: Date): Int =
        dateTime.compareTo(other.dateTime)
}

expect fun Date.toISOString(): String?
expect fun Date.Companion.fromISOString(s: String): Date?
expect val Date.weekOfYear: Int

fun LocalDateTime.toTechlaDate(): Date =
    Date(this)

fun Date.toKotlinxLocalDateTime(): LocalDateTime =
    dateTime

fun Instant.toTechlaDate(): Date =
    techla.base.Date(toLocalDateTime(TimeZone.UTC))

fun Date.toKotlinxInstant(): Instant =
    dateTime.toInstant(TimeZone.UTC)

object DateSerializer : KSerializer<Date> {
    const val DISTANT_FUTURE = "+999999-12-31T23:59:59.000Z"
    const val DISTANT_PAST = "-999999-01-01T00:00:00.000Z"

    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("DateSerializer", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: Date) =
        encoder.encodeString(serialize(value))

    override fun deserialize(decoder: Decoder): Date =
        deserialize(decoder.decodeString())

    fun serialize(obj: Date): String =
        obj.toISOString() ?: throw TechlaError.InternalServerError("Failed to serialize date")

    fun deserialize(string: String): Date =
        Date.fromISOString(string) ?: throw TechlaError.InternalServerError("Failed to deserialize date")
}