package components

import emotion.react.css
import mui.material.FormControl
import mui.material.OutlinedInput
import mui.material.Size
import mui.system.sx
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML.label
import support.DesignSystem
import support.HetchColors
import web.cssom.Color
import web.cssom.important
import web.cssom.px
import web.dom.Element

external interface HInputProps : Props {
    var design: DesignSystem.Input
    var onChange: (String) -> Unit
    var fullWidth: Boolean?
}

val HInput = FC<HInputProps> { props ->
    fun onChange(event: ChangeEvent<Element>) {
        props.onChange((event.target as HTMLInputElement).value)
    }

    FormControl {
        fullWidth = (props.fullWidth == true)
        label {
            css {
                paddingBottom = 8.px
            }
            htmlFor = props.design.name
            +props.design.label
        }

        OutlinedInput {
            sx {
                backgroundColor = important(Color(HetchColors.white))
                color = important(Color(HetchColors.black))
            }


            fullWidth = (props.fullWidth == true)
            required = props.design.required
            size = Size.small
            defaultValue = props.design.value
            onChange = ::onChange
            name = props.design.name
            type = props.design.type
            autoComplete = props.design.autoComplete
        }
    }
}