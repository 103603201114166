package techla.base

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(with = ModificationSerializer::class)
sealed class Modification<out Value> {
    data object Unmodified : Modification<Nothing>()
    data class Modified<Value>(val value: Value) : Modification<Value>()

    val isUnmodified: Boolean get() = this is Unmodified
    val isModified: Boolean get() = this is Modified

    fun getOrNull(): Value? = (this as? Modified)?.value

    fun <T> fold(onUnmodified: () -> T, onModified: (Value) -> T): T =
        when (this) {
            is Unmodified -> onUnmodified()
            is Modified -> onModified(value)
        }

    suspend fun <T> suspendingFold(onUnmodified: () -> T, onModified: suspend (Value) -> T): T =
        when (this) {
            is Unmodified -> onUnmodified()
            is Modified -> onModified(value)
        }

    companion object {
        fun <Value> ofUnmodified(): Modification<Value> = Unmodified
        fun <Value> ofModified(value: Value): Modification<Value> = Modified(value)
    }
}

fun <Value> unmodified(): Modification<Value> = Modification.ofUnmodified()
fun <Value> modifiedOf(value: Value): Modification<Value> = Modification.ofModified(value)

fun <Value, R> Modification<Value>.map(block: (Value) -> R): Modification<R> =
    fold(
        onUnmodified = { Modification.ofUnmodified() },
        onModified = { Modification.ofModified(block(it)) }
    )

fun <Value, R> Modification<Value>.flatMap(block: (Value) -> Modification<R>): Modification<R> =
    fold(
        onUnmodified = { Modification.ofUnmodified() },
        onModified = { block(it) }
    )

@Deprecated("Use ifModified instead", ReplaceWith("ifModified(block)"))
fun <Value, R> Modification<Value>.let(block: (Value) -> R): R? =
    ifModified(block = block)

fun <Value, R> Modification<Value>.ifModified(unmodifiedValue: R? = null, block: (Value) -> R): R? =
    fold(
        onUnmodified = { unmodifiedValue },
        onModified = { block(it) }
    )

suspend fun <Value, R> Modification<Value>.ifModifiedSuspending(unmodifiedValue: R? = null, block: suspend (Value) -> R): R? =
    suspendingFold(
        onUnmodified = { unmodifiedValue },
        onModified = { block(it) }
    )

fun <Value> Modification<Value>.onModified(f: (Value) -> Unit): Modification<Value> =
    fold(
        onUnmodified = { this },
        onModified = { f(it); this }
    )

fun <Value> Modification<Value>.onUnmodified(f: () -> Unit): Modification<Value> =
    fold(
        onUnmodified = { f(); this },
        onModified = { this }
    )

class ModificationSerializer<Value>(private val valueSerializer: KSerializer<Value>) :
    KSerializer<Modification<Value>> {
    override val descriptor: SerialDescriptor
        get() = ListSerializer(valueSerializer).descriptor

    override fun serialize(encoder: Encoder, value: Modification<Value>) {
        when (value) {
            is Modification.Unmodified ->
                encoder.encodeSerializableValue(ListSerializer(valueSerializer), emptyList())

            is Modification.Modified ->
                encoder.encodeSerializableValue(ListSerializer(valueSerializer), listOf(value.value))
        }
    }

    override fun deserialize(decoder: Decoder): Modification<Value> {
        val list = decoder.decodeSerializableValue(ListSerializer(valueSerializer))
        if (list.isEmpty()) return Modification.Unmodified
        return Modification.Modified(list.first())
    }
}
