package techla.reservation

import kotlinx.serialization.Serializable
import techla.base.Contact
import techla.base.Identifier
import techla.base.Modification
import techla.reservation.serializers.ParticipantStatusSerializer

@Serializable
data class Participant(
    val id: Identifier<Participant>,
    val status: Status,
    val contact: Contact,
    val reservationId: Identifier<Reservation>?,
    val ventureId: Identifier<Venture>?,
) {
    @Serializable(with = ParticipantStatusSerializer::class)
    sealed class Status(internal val _discriminator: String) {
        data object Unknown : Status(ParticipantStatusSerializer.UNKNOWN)
        data object Yes : Status(ParticipantStatusSerializer.YES)
        data object No : Status(ParticipantStatusSerializer.NO)
        data object Maybe : Status(ParticipantStatusSerializer.MAYBE)

        val rawValue: String get() = _discriminator

        companion object
    }

    @Serializable
    data class Create(
        val reservationId: Identifier<Reservation>? = null,
        val ventureId: Identifier<in Venture>? = null,
        val contact: Contact,
        val status: Status,
    )

    @Serializable
    data class Edit(
        val contact: Modification<Contact>,
        val status: Modification<Status>,
    )

    @Serializable
    data class Participate(
        val reservationId: Identifier<Reservation>,
        val status: Status,
    )
}
