package techla.base

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(with = KeySerializer::class)
data class Key<Tag>(val rawValue: String) {
    override fun toString(): String {
        return "Key($rawValue)"
    }
}

fun <Target> Key<*>.map(): Key<Target> = Key(rawValue)

fun <Tag> Key.Companion.random(length: Int) =
    Key<Tag>("ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".toCharArray().toList().shuffled().take(length).joinToString(""))

class KeySerializer<Tag>(@Suppress("UNUSED_PARAMETER") tagSerializer: KSerializer<Tag>) : KSerializer<Key<Tag>> {
    override val descriptor: SerialDescriptor
        = PrimitiveSerialDescriptor("KeySerializer", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: Key<Tag>)
        = encoder.encodeString(value.rawValue)

    override fun deserialize(decoder: Decoder): Key<Tag>
        = Key(decoder.decodeString())
}
