@file:UseSerializers(UrlSerializer::class)
package techla.guard

import io.ktor.http.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.UseSerializers
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import techla.base.*

@Serializable
data class UserAuthentication(
    val index: Index<UserAuthentication>,
    val provider: Provider,
    val display: String,
    val status: Status,
    val reason: String?,
    val profileId: Identifier<Profile>?,
    val device: Device,
    val createdAt: Date,
    val govId: String?,
    val tokens: List<Token>,
    @Serializable(with = UrlSerializer::class) val authenticationUrl: Url?,

    @Deprecated("Will be removed in the future")
    val claims: String,
    @Deprecated("Replaced by tokens")
    val autoStartToken: String? = null,
    @Deprecated("Replaced by tokens")
    val token: String? = null,
    @Deprecated("Replaced by tokens")
    val tokenExpiresAt: Date? = null,
    @Deprecated("Replaced by tokens")
    val externalToken: String? = null,
) {
    @Serializable(with = UserAuthenticationProviderSerializer::class)
    sealed class Provider {
        @Serializable(with = UserAuthenticationProviderVendorSerializer::class)
        sealed class Vendor {
            object Unknown: Vendor()
            object CGI: Vendor()
            object BankID: Vendor()
            data class Custom(val name: String): Vendor()
            object Google: Vendor()
            object SEI: Vendor()
            object Test: Vendor()

            val rawValue: String
                get() = UserAuthenticationProviderVendorSerializer.serialize(this).first()

            companion object;

            override fun toString(): String {
                return when (this) {
                    is CGI -> "Vendor.CGI"
                    is BankID -> "Vendor.BankID"
                    is Custom -> "Vendor.Custom($name)"
                    is Google -> "Vendor.Google"
                    is SEI -> "Vendor.SEI"
                    is Test -> "Vendor.Test"
                    is Unknown -> "Vendor.Unknown"
                }
            }
        }

        data object None: Provider()
        data class BankID(val vendor: Vendor): Provider()
        data object EmailLink: Provider()
        data object EmailCode: Provider()
        data object SMSCode: Provider()
        data class Browser(val vendor: Vendor): Provider()
        data object OpenID: Provider()

        val rawValue: String
            get() = UserAuthenticationProviderSerializer.serialize(this).first()

        companion object;

        override fun toString(): String {
            return when (this) {
                is None -> "Provider.None"
                is BankID -> "Provider.BankID(${vendor})"
                is EmailLink -> "Provider.EmailLink"
                is EmailCode -> "Provider.EmailCode"
                is SMSCode -> "Provider.SMSCode"
                is Browser -> "Provider.Browser($vendor)"
                is OpenID -> "Provider.OpenID"
            }
        }
    }

    @Serializable(with = UserAuthenticationStatusSerializer::class)
    sealed class Status {
        object Outstanding: Status()
        object Verified: Status()
        object Complete: Status()
        object Cancelled: Status()
        object Expired: Status()
        object Failed: Status()

        val rawValue: String
            get() = UserAuthenticationStatusSerializer.serialize(this).first()

        companion object;

        override fun toString(): String {
            return when (this) {
                Complete -> "Status.Complete"
                Verified -> "Status.Verified"
                Failed -> "Status.Failed"
                Cancelled -> "Status.Cancelled"
                Expired -> "Status.Expired"
                Outstanding -> "Status.Outstanding"
            }
        }
    }

    @Serializable
    data class Create(
        val govId: String? = null,
        val email: String? = null,
        val phone: String? = null,
        val provider: Provider? = null,
        val device: Device = Device.Other,
        val externalToken: String? = null,
    )

    @Serializable
    data class Verify(
        val code: String? = null,
        val state: String? = null,
    )
}

object UserAuthenticationProviderVendorSerializer: KSerializer<UserAuthentication.Provider.Vendor> {
    private const val CGI  = "cgi"
    private const val BANKID  = "bankid"
    private const val CUSTOM = "custom"
    private const val GOOGLE = "google"
    private const val SEI = "sei"
    private const val TEST = "test"

    override val descriptor: SerialDescriptor
        = ListStringSerializer.descriptor

    override fun serialize(encoder: Encoder, value: UserAuthentication.Provider.Vendor)
        = encoder.encodeSerializableValue(ListStringSerializer, serialize(value))

    override fun deserialize(decoder: Decoder): UserAuthentication.Provider.Vendor
        = deserialize(decoder.decodeSerializableValue(ListStringSerializer))

    fun serialize(obj: UserAuthentication.Provider.Vendor): List<String> {
        return when (obj) {
            is UserAuthentication.Provider.Vendor.CGI -> listOf(CGI)
            is UserAuthentication.Provider.Vendor.BankID -> listOf(BANKID)
            is UserAuthentication.Provider.Vendor.Custom -> listOf(CUSTOM, obj.name)
            is UserAuthentication.Provider.Vendor.Google -> listOf(GOOGLE)
            is UserAuthentication.Provider.Vendor.SEI -> listOf(SEI)
            is UserAuthentication.Provider.Vendor.Test -> listOf(TEST)
            is UserAuthentication.Provider.Vendor.Unknown -> throw TechlaError.PreconditionFailed("Can't encode UserAuthentication.Provider.Vendor.Unknown")
        }
    }

    fun deserialize(data: List<String>): UserAuthentication.Provider.Vendor {
        return when (data.first()) {
            CGI -> UserAuthentication.Provider.Vendor.CGI
            BANKID -> UserAuthentication.Provider.Vendor.BankID
            CUSTOM -> UserAuthentication.Provider.Vendor.Custom(data.getOrNull(1) ?: throw TechlaError.PreconditionFailed("Can't encode UserAuthentication.Provider.Vendor.Custom, missing name"))
            GOOGLE -> UserAuthentication.Provider.Vendor.Google
            SEI -> UserAuthentication.Provider.Vendor.SEI
            TEST -> UserAuthentication.Provider.Vendor.Test
            else -> UserAuthentication.Provider.Vendor.Unknown
        }
    }
}

class UserAuthenticationProviderSerializer: KSerializer<UserAuthentication.Provider> {
    override val descriptor: SerialDescriptor
        = ListStringSerializer.descriptor

    override fun serialize(encoder: Encoder, value: UserAuthentication.Provider)
        = encoder.encodeSerializableValue(ListStringSerializer, serialize(value))

    override fun deserialize(decoder: Decoder): UserAuthentication.Provider
        = deserialize(decoder.decodeSerializableValue(ListStringSerializer))

    companion object {
        private const val NONE = "none"
        private const val BANK_ID = "bid"
        private const val EMAIL_LINK = "email_link"
        private const val EMAIL_CODE = "email_code"
        private const val SMS_CODE = "sms_code"
        private const val BROWSER = "browser"
        private const val OPEN_ID = "openid"

        fun serialize(obj: UserAuthentication.Provider): List<String> {
            return when (obj) {
                is UserAuthentication.Provider.None -> listOf(NONE)
                is UserAuthentication.Provider.BankID -> listOf(BANK_ID) + UserAuthenticationProviderVendorSerializer.serialize(obj.vendor)
                is UserAuthentication.Provider.EmailLink -> listOf(EMAIL_LINK)
                is UserAuthentication.Provider.EmailCode -> listOf(EMAIL_CODE)
                is UserAuthentication.Provider.SMSCode -> listOf(SMS_CODE)
                is UserAuthentication.Provider.Browser -> listOf(BROWSER) + UserAuthenticationProviderVendorSerializer.serialize(obj.vendor)
                is UserAuthentication.Provider.OpenID -> listOf(OPEN_ID)
            }
        }

        fun deserialize(data: List<String>): UserAuthentication.Provider {
            return when (data.first()) {
                NONE -> UserAuthentication.Provider.None
                BANK_ID -> UserAuthentication.Provider.BankID(UserAuthenticationProviderVendorSerializer.deserialize(data.drop(1)))
                EMAIL_LINK -> UserAuthentication.Provider.EmailLink
                EMAIL_CODE -> UserAuthentication.Provider.EmailCode
                SMS_CODE -> UserAuthentication.Provider.SMSCode
                BROWSER -> UserAuthentication.Provider.Browser(UserAuthenticationProviderVendorSerializer.deserialize(data.drop(1)))
                OPEN_ID -> UserAuthentication.Provider.OpenID
                else -> UserAuthentication.Provider.None
            }
        }
    }
}

object UserAuthenticationStatusSerializer: KSerializer<UserAuthentication.Status> {
    override val descriptor: SerialDescriptor
        = ListStringSerializer.descriptor

    override fun serialize(encoder: Encoder, value: UserAuthentication.Status)
        = encoder.encodeSerializableValue(ListStringSerializer, serialize(value))

    override fun deserialize(decoder: Decoder): UserAuthentication.Status
        = deserialize(decoder.decodeSerializableValue(ListStringSerializer))

    private const val OUTSTANDING = "outstanding"
    private const val VERIFIED = "verified"
    private const val COMPLETE = "complete"
    private const val CANCELLED = "cancelled"
    private const val EXPIRED = "expired"
    private const val FAILED = "failed"

    fun serialize(obj: UserAuthentication.Status): List<String> {
        return when (obj) {
            is UserAuthentication.Status.Outstanding -> listOf(OUTSTANDING)
            is UserAuthentication.Status.Verified -> listOf(VERIFIED)
            is UserAuthentication.Status.Complete -> listOf(COMPLETE)
            is UserAuthentication.Status.Cancelled -> listOf(CANCELLED)
            is UserAuthentication.Status.Expired -> listOf(EXPIRED)
            is UserAuthentication.Status.Failed -> listOf(FAILED)
        }
    }

    fun deserialize(data: List<String>): UserAuthentication.Status {
        return when (data.first()) {
            OUTSTANDING -> UserAuthentication.Status.Outstanding
            VERIFIED -> UserAuthentication.Status.Verified
            COMPLETE -> UserAuthentication.Status.Complete
            CANCELLED -> UserAuthentication.Status.Cancelled
            EXPIRED -> UserAuthentication.Status.Expired
            FAILED -> UserAuthentication.Status.Failed
            else -> UserAuthentication.Status.Failed
        }
    }
}
