package components

import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.dom.events.MouseEvent
import support.DesignSystem
import web.cssom.*
import web.dom.Element

external interface FailureProps : PropsWithChildren {
    var design: DesignSystem.Failure
    var onClick: ((MouseEvent<Element, *>) -> Unit)?
}

val Failure = FC<FailureProps> { props ->
    Container {
        maxWidth = "sm"
        sx {
            height = 50.vh
        }
        Stack {
            spacing = responsive(6)
            sx {
                justifyContent = JustifyContent.flexEnd
                height = 100.pct
            }
            Typography {
                align = TypographyAlign.center
                variant = TypographyVariant.h1

                +props.design.title
            }

            Typography {
                align = TypographyAlign.center
                variant = TypographyVariant.body1

                +props.design.details
            }

            HButton { design = props.design.recover; onClick = props.onClick }
        }
    }
}