package techla.guard

import kotlinx.serialization.Serializable
import techla.base.Identifier
import techla.base.Key

@Serializable
data class Application(
    val id: Identifier<Application>,
    val key: Key<Application>,
    val name: String,
    val applicationKey: String,
    val applicationSecret: String? = null
)
