package techla.base

import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.internal.JSJoda.nativeJs as jtNativeJs
import kotlinx.datetime.internal.JSJoda.convert as jtConvert
import kotlinx.datetime.internal.JSJoda.DateTimeFormatter as jtDateTimeFormatter
import kotlinx.datetime.internal.JSJoda.LocalDateTime as jtLocalDateTime

private val strictFormatter = jtDateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
private val lenientFormatterNoMillis = jtDateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss'Z'")
private val lenientFormatterNano = jtDateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss.nnnnnn'Z'")

private fun Date.toJTLocalDateTime(): jtLocalDateTime =
    jtLocalDateTime.of(dateTime.year, dateTime.monthNumber, dateTime.dayOfMonth, dateTime.hour, dateTime.minute, dateTime.second, dateTime.nanosecond)

fun Date.toJavascriptDate(): kotlin.js.Date =
    toJTLocalDateTime().let {
        jtConvert(it).toDate()
    }

fun kotlin.js.Date.toTechlaDate(): Date =
    jtLocalDateTime.from(jtNativeJs(this)).let {
        val dateTime = LocalDateTime(year = it.year(), monthNumber = it.monthValue(), dayOfMonth = it.dayOfMonth(), hour = it.hour(), minute = it.minute(), second = it.second(), nanosecond = it.nano().toInt())
        Date(dateTime)
    }

private fun jtDateTimeFormatter.parseOrNull(s: String): jtLocalDateTime? =
    try {
        jtLocalDateTime.from(this.parse(s))
    } catch (e: Throwable) {
        null
    }

actual fun Date.toISOString(): String? =
    when (this) {
        Date.distantFuture -> DateSerializer.DISTANT_FUTURE
        Date.distantPast -> DateSerializer.DISTANT_PAST
        else -> try {
            jtLocalDateTime.of(dateTime.year, dateTime.monthNumber, dateTime.dayOfMonth, dateTime.hour, dateTime.minute, dateTime.second, dateTime.nanosecond).let {
                it.format(strictFormatter)
            }
        } catch (e: Throwable) {
            null
        }
    }

actual fun Date.Companion.fromISOString(s: String): Date? =
    when (s) {
        DateSerializer.DISTANT_FUTURE -> distantFuture
        DateSerializer.DISTANT_PAST -> distantPast
        else -> (lenientFormatterNoMillis.parseOrNull(s) ?: lenientFormatterNano.parseOrNull(s) ?: strictFormatter.parseOrNull(s))?.let {
            val dateTime = LocalDateTime(year = it.year().toInt(), monthNumber = it.monthValue().toInt(), dayOfMonth = it.dayOfMonth().toInt(), hour = it.hour().toInt(), minute = it.minute().toInt(), second = it.second().toInt(), nanosecond = it.nano().toInt())
            Date(dateTime)
        }
    }

actual val Date.weekOfYear: Int get() =
    toJTLocalDateTime().toLocalDate().isoWeekOfWeekyear().toInt()
