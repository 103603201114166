package support

import js.core.jso
import mui.material.PaletteMode
import mui.material.styles.createTheme
import web.cssom.px

object HetchColors {
    const val black = "#000000"
    const val gray = "#1d1d1d"
    const val green = "#90DBCC"
    const val pink = "#E6AFC1"
    const val white = "#ffffff"
}

val hetchTheme = createTheme(jso {
    palette = jso {
        mode = PaletteMode.dark
        background = jso {
            default = HetchColors.black
            paper = HetchColors.black
        }
        primary = jso {
            main = HetchColors.green
        }
        secondary = jso {
            main = HetchColors.pink
        }
    }
    shape = jso {
        borderRadius = 0.px
    }
    typography = jso {
        h1 = jso { fontSize = "2.6rem" }
        h2 = jso { fontSize = "1.75rem" }
        h3 = jso { fontSize = "1.25rem" }
        body1 = jso { fontSize = "1rem" }
        button = jso { fontSize = "0.813rem" }
    }
    components = jso {
        MuiButtonBase = jso {
            defaultProps = jso {
                disableRipple = true
            }
            styleOverrides = jso {
                root = jso {
                    textTransform = "uppercase"
                }
            }
        }
        MuiButton = jso {
            styleOverrides = jso {
                root = jso {
                    textTransform = "uppercase"
                    padding = 10.px
                }
            }
        }
        MuiLink = jso {
            styleOverrides = jso {
                root = jso {
                    color = HetchColors.white
                    textDecoration = "none"
                }
            }
        }
        MuiChip = jso {
            defaultProps = jso {
                size = "small"
            }
            styleOverrides = jso {
                root = jso {
                    borderRadius = 0.px
                }
            }
        }

        MuiInputBase = jso {
            styleOverrides = jso {
                root = jso {
                    backgroundColor = HetchColors.white
                    textFillColor = HetchColors.black

                }
            }
        }
    }
})