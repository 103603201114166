package techla.reservation

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import techla.base.*

@Serializable
data class Resource(
    val id: Identifier<Resource>,
    val key: Key<Resource>,
    val name: String,
    val inAdvance: Int,
    val restricted: Boolean,
    val timeZone: String,
    val locale: String,
    val tags: List<Tag>,
) : BaseResource {
    @Serializable(with = ResourceTagSerializer::class)
    sealed class Tag {
        data object Unknown: Tag()

        data class Capacity(val seats: Int): Tag()
        data class Location(val building: String, val floor: Int): Tag()
        data class Distance(val from: String, val x: Int, val y: Int): Tag()
        data class Equipment(val name: String): Tag()
        data class VisitingAdress(val visitingAdress: Address): Tag()
        data class Position(val coordinate: Coordinate): Tag()
        data class Area(val area: String): Tag()
        data class PlusCode(val plusCode: String): Tag()

        val rawValue: String
            get() = ResourceTagSerializer.serialize(this).first()!!

        companion object
    }

    @Serializable
    data class Create(
        val key: Key<Resource>,
        val name: String,
        val inAdvance: Int = 0,
        val restricted: Boolean = false,
        val timeZone: String = "Europe/Stockholm",
        val locale: String = "sv-SE",
        val tags: List<Tag> = emptyList(),
    )

    @Serializable
    data class Edit(
        val key: Modification<Key<Resource>> = Modification.Unmodified,
        val name: Modification<String> = Modification.Unmodified,
        val inAdvance: Modification<Int> = Modification.Unmodified,
        val restricted: Modification<Boolean> = Modification.Unmodified,
        val timeZone: Modification<String> = Modification.Unmodified,
        val locale: Modification<String> = Modification.Unmodified,
        val tags: Modification<List<Tag>> = Modification.Unmodified,
    )

    @Serializable
    data class Batch(
        val ids: List<Identifier<out BaseResource>>,
    )
}

object ResourceTagSerializer: KSerializer<Resource.Tag> {
    override val descriptor: SerialDescriptor
            = ListNullableStringSerializer.descriptor

    override fun serialize(encoder: Encoder, value: Resource.Tag)
            = encoder.encodeSerializableValue(ListNullableStringSerializer, serialize(value))

    override fun deserialize(decoder: Decoder): Resource.Tag
            = deserialize(decoder.decodeSerializableValue(ListNullableStringSerializer))

    const val CAPACITY = "capacity"
    const val LOCATION = "location"
    const val DISTANCE = "distance"
    const val EQUIPMENT = "equipment"
    const val VISITING_ADRESS = "visiting_adress"
    const val POSITION = "position"
    const val AREA = "area"
    const val PLUS_CODE = "plus_code"

    fun serialize(value: Resource.Tag): List<String?> {
        return when (value) {
            is Resource.Tag.Unknown -> throw TechlaError.PreconditionFailed("Can't encode Resource.Tag.Unknown")
            is Resource.Tag.Capacity -> listOf(CAPACITY, value.seats.toString())
            is Resource.Tag.Location -> listOf(LOCATION, value.building, value.floor.toString())
            is Resource.Tag.Distance -> listOf(DISTANCE, value.from, value.x.toString(), value.y.toString())
            is Resource.Tag.Equipment -> listOf(EQUIPMENT, value.name)
            is Resource.Tag.VisitingAdress -> listOf(VISITING_ADRESS) + AddressSerializer.serialize(value.visitingAdress)
            is Resource.Tag.Position -> listOf(POSITION) + CoordinateSerializer.serialize(value.coordinate)
            is Resource.Tag.Area -> listOf(AREA, value.area)
            is Resource.Tag.PlusCode -> listOf(PLUS_CODE, value.plusCode)
        }
    }

    fun deserialize(data: List<String?>): Resource.Tag {
        return when (data.first()) {
            CAPACITY -> Resource.Tag.Capacity(data[1]!!.toInt())
            LOCATION -> Resource.Tag.Location(data[1]!!, data[2]!!.toInt())
            DISTANCE -> Resource.Tag.Distance(data[1]!!, data[2]!!.toInt(), data[3]!!.toInt())
            EQUIPMENT -> Resource.Tag.Equipment(data[1]!!)
            VISITING_ADRESS -> Resource.Tag.VisitingAdress(AddressSerializer.deserialize(data.drop(1)))
            POSITION -> Resource.Tag.Position(CoordinateSerializer.deserialize(data.drop(1)))
            AREA -> Resource.Tag.Area(data[1]!!)
            PLUS_CODE -> Resource.Tag.PlusCode(data[1]!!)
            else -> Resource.Tag.Unknown
        }
    }
}
