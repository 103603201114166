package components

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.PropsWithChildren
import react.PropsWithClassName
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.form
import web.dom.Element

external interface HFormProps : PropsWithChildren, PropsWithClassName {
    var onSubmit: suspend () -> Unit
}

val HForm = FC<HFormProps> { props ->
    fun onSubmit(event: FormEvent<Element>) {
        event.preventDefault()
        MainScope().launch {
            props.onSubmit()
        }
    }

    form {
        this.onSubmit = ::onSubmit
        className = props.className
        +props.children
    }
}