package support

import techla.base.Either
import techla.base.TechlaError
import techla.base.Warning
import techla.base.techla_log
import web.html.ButtonType

object DesignSystem {
    data class Button(
        val type: ButtonType, val text: String, val disabled: Boolean = false, val danger: Boolean = false
    )

    sealed class Input(
        open val name: String,
        open val label: String,
        open val value: String,
        open val required: Boolean = false,
    ) {
        abstract val type: String
        abstract val autoComplete: String?

        data class Select(
            override val name: String,
            override val label: String,
            override val value: String,
            override val required: Boolean = false,
            val options: List<Option>,
        ) : Input(name, label, value, required) {
            override val type = ""
            override val autoComplete = null
        }

        data class Text(
            override val name: String,
            override val label: String,
            override val value: String,
            override val required: Boolean = false,
        ) : Input(name, label, value, required) {
            override val type = "text"
            override val autoComplete = null
        }

        data class OneTimeCode(
            override val name: String,
            override val label: String,
            override val value: String,
            override val required: Boolean = false,
        ) : Input(name, label, value, required) {
            override val type = "text"
            override val autoComplete = "one-time-code"
        }

        data class Email(
            override val name: String,
            override val label: String,
            override val value: String,
            override val required: Boolean = false,
        ) : Input(name, label, value, required) {
            override val type = "email"
            override val autoComplete = "email"
        }

        data class Date(
            override val name: String,
            override val label: String,
            override val value: String,
            override val required: Boolean = false,
        ) : Input(name, label, value, required) {
            override val type = "date"
            override val autoComplete = null
        }
    }

    data class Option(val title: String, val value: String?)

    data class Failure(
        val title: String,
        val details: String,
        val recover: Button,
    )
}

fun failure(failure: Either<List<Warning>, Throwable>, isSignedIn: Boolean): DesignSystem.Failure {
    techla_log("FAILURE: $failure")


    val message = when (failure) {
        is Either.Left -> failure.value.joinToString(", ")
        is Either.Right ->
            when (failure.value) {
                is TechlaError.ServiceUnavailable -> "The internet connection appears to be disconnected."
                else -> failure.value.message
            }
    }

    return DesignSystem.Failure(
        title = "Oops, an error has occurred",
        details = message ?: "An unknown error has occurred, please try again later",
        recover = DesignSystem.Button(text = "OK", type = ButtonType.button),
    )
}