package techla.reservation

import io.ktor.client.HttpClient
import kotlinx.serialization.builtins.ListSerializer
import techla.base.*

class ReservationAPI(client: HttpClient): BaseAPI(client) {
    companion object {
        const val local = "http://localhost:8080"
        const val sandbox = "https://sandbox.techla.io"
        const val shared = "https://api.techla.io"
    }

    init {
        host = sandbox
    }

    suspend fun findResources(keys: List<Key<Resource>>): Outcome<List<Resource>> {
        return ReservationAPIResource.FindResources(keys)
            .call(this)
            .decode(ListSerializer(Resource.serializer()))
    }

    suspend fun listResources(): Outcome<List<Resource>> {
        return ReservationAPIResource.ListResources
            .call(this)
            .decode(ListSerializer(Resource.serializer()))
    }

    suspend fun listResources(index: PageIndex): Outcome<PageContent<Resource>> {
        return ReservationAPIResource.ListResourcesWithPaging(index)
            .call(this)
            .decode(PageContent.serializer(Resource.serializer()))
    }

    suspend fun getResources(batch: Resource.Batch): Outcome<List<Resource>> {
        return ReservationAPIResource.GetResources(batch)
            .call(this)
            .decode(ListSerializer(Resource.serializer()))
    }

    suspend fun getResource(id: Identifier<in Resource>): Outcome<Resource> {
        return ReservationAPIResource.GetResource(id)
            .call(this)
            .decode(Resource.serializer())
    }

    suspend fun createResource(create: Resource.Create): Outcome<Resource> {
        return ReservationAPIResource.CreateResource(create)
            .call(this)
            .decode(Resource.serializer())
    }

    suspend fun editResource(id: Identifier<in Resource>, edit: Resource.Edit): Outcome<Resource> {
        return ReservationAPIResource.EditResource(id, edit)
            .call(this)
            .decode(Resource.serializer())
    }

    suspend fun deleteResource(id: Identifier<in Resource>): Outcome<Unit> {
        return ReservationAPIResource.DeleteResource(id)
            .call(this)
            .map {}
    }

    suspend fun findReservations(filter: Reservation.Filter? = null): Outcome<List<Reservation>> {
        return ReservationAPIResource.FindReservations(filter)
            .call(this)
            .decode(ListSerializer(Reservation.serializer()))
    }

    suspend fun listAllReservations(): Outcome<List<Reservation>> {
        return ReservationAPIResource.ListReservations(null, null)
            .call(this)
            .decode(ListSerializer(Reservation.serializer()))
    }

    suspend fun listReservations(resource: Either<Identifier<in Resource>, Key<Resource>>): Outcome<List<Reservation>> {
        return ReservationAPIResource.ListReservations(resource.leftOrNull(), resource.rightOrNull())
            .call(this)
            .decode(ListSerializer(Reservation.serializer()))
    }

    suspend fun listReservations(resource: Either<Identifier<in Resource>, Key<Resource>>, index: PageIndex): Outcome<PageContent<Reservation>> {
        return ReservationAPIResource.ListReservationsWithPaging(resource.leftOrNull(), resource.rightOrNull(), index)
            .call(this)
            .decode(PageContent.serializer(Reservation.serializer()))
    }

    suspend fun getReservation(id: Identifier<Reservation>): Outcome<Reservation> {
        return ReservationAPIResource.GetReservation(id)
            .call(this)
            .decode(Reservation.serializer())
    }

    suspend fun createReservation(create: Reservation.Create): Outcome<Reservation> {
        return ReservationAPIResource.CreateReservation(create)
            .call(this)
            .decode(Reservation.serializer())
    }

    suspend fun editReservation(id: Identifier<Reservation>, edit: Reservation.Edit): Outcome<Reservation> {
        return ReservationAPIResource.EditReservation(id, edit)
            .call(this)
            .decode(Reservation.serializer())
    }

    suspend fun deleteReservation(id: Identifier<Reservation>): Outcome<Unit> {
        return ReservationAPIResource.DeleteReservation(id)
            .call(this)
            .map {}
    }

    suspend fun listParticipants(): Outcome<List<Participant>> {
        return ReservationAPIResource.ListParticipants(null, null)
            .call(this)
            .decode(ListSerializer(Participant.serializer()))
    }

    suspend fun listParticipants(to: Either<Identifier<Reservation>, Identifier<in Venture>>): Outcome<List<Participant>> {
        return ReservationAPIResource.ListParticipants(to.leftOrNull(), to.rightOrNull())
            .call(this)
            .decode(ListSerializer(Participant.serializer()))
    }

    suspend fun listParticipants(index: PageIndex): Outcome<PageContent<Participant>> {
        return ReservationAPIResource.ListParticipantsWithPaging(null, null, index)
            .call(this)
            .decode(PageContent.serializer(Participant.serializer()))
    }

    suspend fun listParticipants(to: Either<Identifier<Reservation>, Identifier<in Venture>>, index: PageIndex): Outcome<PageContent<Participant>> {
        return ReservationAPIResource.ListParticipantsWithPaging(to.leftOrNull(), to.rightOrNull(), index)
            .call(this)
            .decode(PageContent.serializer(Participant.serializer()))
    }

    suspend fun getParticipant(id: Identifier<Participant>): Outcome<Participant> {
        return ReservationAPIResource.GetParticipant(id)
            .call(this)
            .decode(Participant.serializer())
    }

    suspend fun createParticipant(create: Participant.Create): Outcome<Participant> {
        return ReservationAPIResource.CreateParticipant(create)
            .call(this)
            .decode(Participant.serializer())
    }

    suspend fun editParticipant(id: Identifier<Participant>, edit: Participant.Edit): Outcome<Participant> {
        return ReservationAPIResource.EditParticipant(id, edit)
            .call(this)
            .decode(Participant.serializer())
    }

    suspend fun deleteParticipant(id: Identifier<Participant>): Outcome<Unit> {
        return ReservationAPIResource.DeleteParticipant(id)
            .call(this)
            .map {}
    }

    suspend fun participate(participate: Participant.Participate): Outcome<Unit> {
        return ReservationAPIResource.Participate(participate)
            .call(this)
            .map {}
    }

    suspend fun findVentures(keys: List<Key<Venture>>): Outcome<List<Venture>> {
        return ReservationAPIResource.FindVentures(keys)
            .call(this)
            .decode(ListSerializer(Venture.serializer()))
    }

    suspend fun listVentures(): Outcome<List<Venture>> {
        return ReservationAPIResource.ListVentures
            .call(this)
            .decode(ListSerializer(Venture.serializer()))
    }

    suspend fun listVentures(index: PageIndex, labels: List<Venture.Tag.Label>? = null): Outcome<PageContent<Venture>> {
        return ReservationAPIResource.ListVenturesWithPaging(index, labels)
            .call(this)
            .decode(PageContent.serializer(Venture.serializer()))
    }

    suspend fun getVentures(batch: Venture.Batch): Outcome<List<Venture>> {
        return ReservationAPIResource.GetVentures(batch)
            .call(this)
            .decode(ListSerializer(Venture.serializer()))
    }

    suspend fun getVenture(id: Identifier<in Venture>): Outcome<Venture> {
        return ReservationAPIResource.GetVenture(id)
            .call(this)
            .decode(Venture.serializer())
    }

    suspend fun createVenture(create: Venture.Create): Outcome<Venture> {
        return ReservationAPIResource.CreateVenture(create)
            .call(this)
            .decode(Venture.serializer())
    }

    suspend fun editVenture(id: Identifier<in Venture>, edit: Venture.Edit): Outcome<Venture> {
        return ReservationAPIResource.EditVenture(id, edit)
            .call(this)
            .decode(Venture.serializer())
    }

    suspend fun deleteVenture(id: Identifier<in Venture>): Outcome<Unit> {
        return ReservationAPIResource.DeleteVenture(id)
            .call(this)
            .map {}
    }

    suspend fun findAvailability(resource: Key<Resource>, filter: Reservation.Filter, style: Reservation.Style? = null): Outcome<List<Availability>> {
        return ReservationAPIResource.FindAvailability(resource, filter, style)
            .call(this)
            .decode(ListSerializer(Availability.serializer()))
    }

    suspend fun listLabels(): Outcome<List<Venture.Tag.Label>> {
        return ReservationAPIResource.ListLabels
            .call(this)
            .decode(ListSerializer(Venture.Tag.serializer()))
            .map { it.filterIsInstance<Venture.Tag.Label>() }
    }
}


