package views

import StoreContext
import components.*
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.icons.material.PersonAdd
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.useContext
import react.useState
import screens.UsersScreen
import support.sceneInputOf
import support.useAsyncEffect
import techla.guard.Profile
import web.cssom.JustifyContent
import web.cssom.px

fun <T> List<T>.safeSlice(indices: IntRange): List<T> {
    if (indices.isEmpty()) return listOf()
    if (this.isEmpty()) return this
    val start = indices.first.coerceAtLeast(0)
    val end = indices.last.coerceAtMost(this.size - 1)

    return this.subList(start, end + 1).toList()
}


val Users = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(UsersScreen.ViewModel.None as UsersScreen.ViewModel)
    val navigate = useNavigate()

    var search by useState("")
    var rowsPerPage by useState(5)
    var page by useState(0)

    var company by useState("")
    var name by useState("")
    var email by useState("")

    val filteredProfiles = store.profiles.filter { profile ->
        search.isBlank() || profile.firstName?.lowercase()?.contains(search) == true || profile.lastName?.lowercase()
            ?.contains(search) == true || profile.email?.lowercase()?.contains(search) == true
    }

    suspend fun onSubmit() {
        UsersScreen.create(sceneInputOf(store, viewModel), email = email, name = name, company = company)
            .also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
    }

    suspend fun onSubmitEdit() {
        UsersScreen.edit(sceneInputOf(store, viewModel), email = email, name = name, company = company)
            .also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
    }

    fun onClose() = MainScope().launch {
        UsersScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    fun openCreateDialog() = MainScope().launch {
        UsersScreen.creating(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    fun openEditDialog(profile: Profile) = MainScope().launch {
        UsersScreen.edit(sceneInputOf(store, viewModel), profile).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    fun openDeleteDialog(profile: Profile) = MainScope().launch {
        UsersScreen.deleting(sceneInputOf(store, viewModel), profile).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        window.scrollTo(0.0, 0.0)
        when (viewModel) {
            is UsersScreen.ViewModel.None -> UsersScreen.load(sceneInputOf(store, viewModel))
                .also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }

            is UsersScreen.ViewModel.Ready -> {}
            is UsersScreen.ViewModel.Success -> {}
            is UsersScreen.ViewModel.Creating -> {}
            is UsersScreen.ViewModel.Edit -> {}
            is UsersScreen.ViewModel.Deleting -> {}
            is UsersScreen.ViewModel.Deleted -> {}
            is UsersScreen.ViewModel.ValidationError -> {}
            is UsersScreen.ViewModel.Failed -> navigate("/")
        }
    }

    main {
        HNavbar {}
        if (viewModel is UsersScreen.ViewModel.Failed) {
            Failure { design = viewModel.failure; onClick = { navigate("/") } }
        } else {
            Container {
                sx {
                    paddingBottom = 48.px
                }
                Stack {
                    spacing = responsive(6)

                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.spaceBetween
                        }

                        Typography {
                            align = TypographyAlign.left
                            variant = TypographyVariant.h1

                            +"USERS"
                        }

                        HButton {
                            design = viewModel.openCreate
                            onClick = { _ -> openCreateDialog() }

                            PersonAdd {
                                css {
                                    paddingRight = 4.px
                                }
                            }
                        }
                    }

                    HInput {
                        design = viewModel.search
                        onChange = { value -> search = value.lowercase() }
                    }

                    Paper {
                        TableContainer {
                            component = Paper

                            Table {
                                TableHead {
                                    TableRow {
                                        TableCell {
                                            +"Name"
                                        }
                                        TableCell {
                                            +"Email"
                                        }
                                        TableCell {
                                            +"Company"
                                        }
                                        TableCell {}
                                    }
                                }

                                TableBody {
                                    filteredProfiles.safeSlice((page * rowsPerPage) until (page * rowsPerPage + rowsPerPage))
                                        .map { profile ->

                                            TableRow {
                                                TableCell {
                                                    +profile.lastName!!
                                                }
                                                TableCell {
                                                    +profile.email!!
                                                }
                                                TableCell {
                                                    +profile.firstName!!
                                                }
                                                TableCell {
                                                    HButton {
                                                        design = viewModel.editButton
                                                        onClick = { _ -> openEditDialog(profile) }
                                                    }
                                                }
                                                TableCell {
                                                    HButton {
                                                        design = viewModel.deleteButton
                                                        onClick = { _ -> openDeleteDialog(profile) }
                                                    }
                                                }
                                            }

                                        }
                                }
                            }
                        }

                        TablePagination {
                            rowsPerPageOptions = arrayOf(5, 10, 15, 20, 25)
                            component = div
                            count = filteredProfiles.size
                            this.rowsPerPage = rowsPerPage
                            this.page = page
                            onPageChange = { _, newPage ->
                                page = newPage.toInt()
                            }
                            onRowsPerPageChange = { event ->
                                // https://mui.com/material-ui/api/table-pagination/#props
                                // The documentation (link above) says that `event.target` should be either `HTMLTextAreaElement`
                                // or `HTMLInputElement` but casting to them don't work. The element rendered in the DOM is a
                                // `input` so `(event.target as HTMLInputElement).value` should work in my opinion (but it doesn't).
                                // Using `asDynamic()` works for now but might be a problem in the future.
                                rowsPerPage = event.target.asDynamic().value as Int
                                page = 0
                            }
                        }
                    }
                }

                HDialog {
                    open = viewModel is UsersScreen.ViewModel.Success
                    onClose = { onClose() }
                    title = viewModel.updateTitle

                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.center
                        }
                        HButton {
                            design = viewModel.ok
                            onClick = { _ -> onClose() }
                        }
                    }
                }

                HDialog {
                    open = viewModel is UsersScreen.ViewModel.Deleted
                    onClose = { onClose() }
                    title = "USER REMOVED"

                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.center
                        }
                        HButton {
                            design = viewModel.ok
                            onClick = { _ -> onClose() }
                        }
                    }
                }

                HDialog {
                    open = viewModel is UsersScreen.ViewModel.Creating
                    onClose = { onClose() }
                    title = "CREATE USER"

                    HForm {
                        onSubmit = ::onSubmit

                        Stack {
                            spacing = responsive(2)

                            HInput {
                                design = viewModel.company
                                onChange = { value -> company = value }
                            }
                            HInput {
                                design = viewModel.name
                                onChange = { value -> name = value }
                            }
                            HInput {
                                design = viewModel.email
                                onChange = { value -> email = value }
                            }

                            Stack {
                                direction = responsive(StackDirection.row)
                                sx {
                                    justifyContent = JustifyContent.center
                                }
                                HButton { design = viewModel.submit }
                            }
                        }
                    }
                }

                HDialog {
                    open = viewModel is UsersScreen.ViewModel.Edit
                    onClose = { onClose() }
                    title = "EDIT USER"

                    HForm {
                        onSubmit = ::onSubmitEdit

                        Stack {
                            spacing = responsive(2)

                            HInput {
                                design = viewModel.company
                                onChange = { value -> company = value }
                            }
                            HInput {
                                design = viewModel.name
                                onChange = { value -> name = value }
                            }
                            HInput {
                                design = viewModel.email
                                onChange = { value -> email = value }
                            }

                            Stack {
                                direction = responsive(StackDirection.row)
                                sx {
                                    justifyContent = JustifyContent.center
                                }
                                HButton { design = viewModel.submit }
                            }
                        }
                    }
                }


                HDialog {
                    open = viewModel is UsersScreen.ViewModel.Deleting
                    onClose = { onClose() }
                    title = "ARE YOU SURE YOU WANT TO REMOVE"

                    Typography {
                        variant = TypographyVariant.h2
                        align = TypographyAlign.center

                        +viewModel.userName
                    }

                    Typography {
                        variant = TypographyVariant.body1
                        align = TypographyAlign.center

                        +viewModel.userCompany
                    }

                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.center
                        }
                        HButton {
                            design = viewModel.confirmDelete
                            onClick = { _ ->
                                MainScope().launch {
                                    UsersScreen.delete(sceneInputOf(store, viewModel))
                                        .also { (viewModel, actions) ->
                                            setViewModel(viewModel)
                                            dispatch(actions)
                                        }
                                }

                            }
                        }
                    }
                }
            }
        }
    }
}