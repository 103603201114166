package techla.base

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

@Serializable(with = DeviceSerializer::class)
sealed class Device {
    object Unknown: Device()
    object Other: Device()
    data class Apple(val hardware: String): Device()
    data class Android(val hardware: String): Device()
    data class Web(val userAgent: String): Device()

    val rawValue: String
        get() = DeviceSerializer.serialize(this).first()

    companion object {}
    override fun toString(): String {
        return when (this) {
            is Unknown -> "Device.Unknown"
            is Other -> "Device.Other"
            is Apple -> "Device.Apple"
            is Android -> "Device.Android"
            is Web -> "Device.Web"
        }
    }
}

object DeviceSerializer: KSerializer<Device> {
    override val descriptor: SerialDescriptor
            = ListStringSerializer.descriptor

    override fun serialize(encoder: Encoder, value: Device)
            = encoder.encodeSerializableValue(ListStringSerializer, serialize(value))

    override fun deserialize(decoder: Decoder): Device
            = deserialize(decoder.decodeSerializableValue(ListStringSerializer))

    const val OTHER = "other"
    const val APPLE = "apple"
    const val ANDROID = "android"
    const val WEB = "web"

    fun serialize(obj: Device): List<String> {
        return when (obj) {
            is Device.Unknown -> throw TechlaError.PreconditionFailed("Can't encode Device.Unknown")
            is Device.Other -> listOf(OTHER)
            is Device.Apple -> listOf(APPLE, obj.hardware)
            is Device.Android -> listOf(ANDROID, obj.hardware)
            is Device.Web -> listOf(WEB, obj.userAgent)
        }
    }

    fun deserialize(data: List<String>): Device {
        return when (data.first()) {
            OTHER -> Device.Other
            APPLE -> Device.Apple(data[1])
            ANDROID -> Device.Android(data[1])
            WEB -> Device.Web(data[1])
            else -> Device.Unknown
        }
    }
}