package techla.base

import io.ktor.http.HttpStatusCode

sealed class TechlaError(val statusCode: HttpStatusCode, message: String): Exception(message) {
    open class BadRequest(message: String)
        : TechlaError(HttpStatusCode.BadRequest, message)

    open class Unauthorized(message: String)
        : TechlaError(HttpStatusCode.Unauthorized, message)

    open class NotFound(message: String)
        : TechlaError(HttpStatusCode.NotFound, message)

    open class PreconditionFailed(message: String)
        : TechlaError(HttpStatusCode.PreconditionFailed, message)

    open class UnsupportedMediaType(message: String)
        : TechlaError(HttpStatusCode.UnsupportedMediaType, message)

    open class InternalServerError(message: String)
        : TechlaError(HttpStatusCode.InternalServerError, message)

    open class ServiceUnavailable(message: String)
        : TechlaError(HttpStatusCode.ServiceUnavailable, message)

    open class Conflict(message: String)
        : TechlaError(HttpStatusCode.Conflict, message)

    override fun toString(): String {
        return when (this) {
            is BadRequest -> "TechlaError.BadRequest($message)"
            is Unauthorized -> "TechlaError.Unauthorized($message)"
            is NotFound -> "TechlaError.NotFound($message)"
            is PreconditionFailed -> "TechlaError.PreconditionFailed($message)"
            is UnsupportedMediaType -> "TechlaError.UnsupportedMediaType($message)"
            is InternalServerError -> "TechlaError.InternalServerError($message)"
            is ServiceUnavailable -> "TechlaError.ServiceUnavailable($message)"
            is Conflict -> "TechlaError.Conflict($message)"
        }
    }
}
