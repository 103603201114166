package techla.guard

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.*
import techla.base.*

@Serializable
data class Workspace(
    val id: Identifier<Workspace>,
    val key: Key<Workspace>,
    val name: String,
    val environment: Environment,
    val storage: Storage,
    val providers: List<UserAuthentication.Provider>,
    val authenticationValidForHours: Long,
    val display: String? = null,
    val hashedProfile: Boolean = false,

    val standard: List<Access> = emptyList(),
    val services: List<Key<Service>> = emptyList()
) {
    @Serializable(with = WorkspaceStorageSerializer::class)
    sealed class Storage(internal val _discriminator: String) {
        object Postgres: Storage(WorkspaceStorageSerializer.POSTGRES)
        object Sandbox: Storage(WorkspaceStorageSerializer.SANDBOX)

        val rawValue: String get() = _discriminator

        companion object;

        override fun toString(): String {
            return when (this) {
                Postgres -> "Storage.Postgres"
                Sandbox -> "Storage.Sandbox"
            }
        }
    }
}

data class WorkspaceStorageComponents(
    val rawValue: String,
)

fun Workspace.Storage.flatten() =
    when (this) {
        is Workspace.Storage.Postgres -> WorkspaceStorageComponents(rawValue)
        is Workspace.Storage.Sandbox -> WorkspaceStorageComponents(rawValue)
    }

fun Workspace.Storage.Companion.unflatten(components: WorkspaceStorageComponents) =
    when (components.rawValue) {
        WorkspaceStorageSerializer.POSTGRES -> Workspace.Storage.Postgres
        WorkspaceStorageSerializer.SANDBOX -> Workspace.Storage.Sandbox
        else -> Workspace.Storage.Sandbox
    }

object WorkspaceStorageSerializer: KSerializer<Workspace.Storage> {
    const val POSTGRES = "postgres"
    const val SANDBOX = "sandbox"

    override val descriptor: SerialDescriptor = String.serializer().descriptor

    override fun deserialize(decoder: Decoder): Workspace.Storage {
        require(decoder is JsonDecoder)

        val jsonObject = decoder.decodeJsonElement().jsonObject

        return deserialize(jsonObject)
    }

    fun deserialize(jsonObject: JsonObject): Workspace.Storage {
        val discriminator = jsonObject["_discriminator"]
        if (discriminator == null || discriminator !is JsonPrimitive) {
            return Workspace.Storage.Sandbox
        }

        val components = WorkspaceStorageComponents(
            rawValue = discriminator.content,
        )

        return Workspace.Storage.unflatten(components)
    }

    override fun serialize(encoder: Encoder, value: Workspace.Storage) {
        require(encoder is JsonEncoder)

        encoder.encodeJsonElement(serialize(value))
    }

    fun serialize(value: Workspace.Storage): JsonObject {
        return buildJsonObject {
            val components = value.flatten()
            put("_discriminator", components.rawValue)
        }
    }
}
