package techla.base

import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

expect fun uniqueIdentifierValue(): String

@Serializable(with = IdentifierSerializer::class)
data class Identifier<Tag>(val rawValue: String = uniqueIdentifierValue()) {
    override fun toString(): String {
        return "Identifier($rawValue)"
    }
}

@Deprecated("Use flatIdentifier instead")
fun <Target> Identifier<*>.map(): Identifier<Target> = Identifier(rawValue)

inline fun <Source, Target> Identifier<Source>.flatIdentifier(transform: (String) -> Identifier<Target> = { Identifier(it) }): Identifier<Target> = transform(rawValue)
inline fun <Source, Target> List<Identifier<Source>>.flatIdentifier(transform: (String) -> Identifier<Target> = { Identifier(it) }): List<Identifier<Target>> = map { transform(it.rawValue) }

class IdentifierSerializer<Tag>(@Suppress("UNUSED_PARAMETER") tagSerializer: KSerializer<Tag>) : KSerializer<Identifier<Tag>> {
    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("IdentifierSerializer", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: Identifier<Tag>) = encoder.encodeString(value.rawValue)

    override fun deserialize(decoder: Decoder): Identifier<Tag> = Identifier(decoder.decodeString())
}
