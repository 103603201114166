package views

import StoreContext
import components.*
import kotlinx.coroutines.isActive
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.useContext
import react.useState
import screens.CodeScreen
import screens.EnterScreen
import support.sceneInputOf
import support.useAsyncEffect
import web.cssom.*

val Code = FC<Props> {
    StoreContext
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(CodeScreen.ViewModel.None as CodeScreen.ViewModel)

    var code by useState("")
    val navigate = useNavigate()

    suspend fun onSubmit() {
        CodeScreen.handleSubmit(sceneInputOf(store, viewModel), code).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is CodeScreen.ViewModel.None -> CodeScreen.load(sceneInputOf(store, viewModel))
                .also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }

            is CodeScreen.ViewModel.Ready -> {}
            is CodeScreen.ViewModel.Submitted -> {
                if (store.isSignedIn) navigate("/book")
                else navigate("/")
            }

            is CodeScreen.ViewModel.Failed -> {}
        }
    }

    main {
        HNavbar {}
        if (viewModel is CodeScreen.ViewModel.Failed) {
            Failure { design = viewModel.failure; onClick = { navigate("/") } }
        } else {

            Container {
                maxWidth = "sm"
                sx {
                    height = 50.vh
                }

                Stack {
                    spacing = responsive(6)
                    sx {
                        justifyContent = JustifyContent.flexEnd
                        height = 100.pct
                    }

                    Typography {
                        align = TypographyAlign.center
                        variant = TypographyVariant.h1

                        +"CHECK MAILBOX"
                    }

                    Typography {
                        align = TypographyAlign.center
                        variant = TypographyVariant.body1

                        +"Please check your mailbox for the magic code that will let you in :)"
                    }

                    HForm {
                        onSubmit = ::onSubmit

                        Stack {
                            spacing = responsive(2)

                            HInput {
                                design = viewModel.codeInput
                                onChange = { value -> code = value }
                            }

                            HButton { design = viewModel.submit }
                        }
                    }
                }
            }
        }
    }
}