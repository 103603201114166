package support

import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.ReactNode
import react.useEffect

fun useAsyncEffect(
    vararg dependencies: dynamic, effect: suspend (coroutineScope: CoroutineScope) -> Unit
) {
    useEffect(dependencies) {
        val job = MainScope().launch {
            effect(this)
        }
        cleanup {
            job.cancel()
        }
    }
}

fun ReactNode(
    source: Int,
): ReactNode = source.unsafeCast<ReactNode>()
