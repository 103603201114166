package screens

import services.me
import services.verifyUserAuthentication
import support.*
import techla.base.*
import web.html.ButtonType

object CodeScreen {
    sealed class ViewModel(
        val codeInput: DesignSystem.Input.OneTimeCode = DesignSystem.Input.OneTimeCode(
            name = "code", label = "Code", value = "", required = true
        ),
        val submit: DesignSystem.Button = DesignSystem.Button(type = ButtonType.submit, text = "NEXT"),
    ) {
        object None : ViewModel()
        object Ready : ViewModel()
        object Submitted : ViewModel()
        data class Failed(
            val failure: DesignSystem.Failure,
        ) : ViewModel()

        fun ready() = Ready

        fun submitted() = Submitted

        fun failed(failure: Either<List<Warning>, Throwable>, isSignedIn: Boolean): ViewModel = Failed(failure = failure(failure, isSignedIn))

    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>): Scene.Output<ViewModel> {
        val failure = when (result) {
            is Either.Left -> result
            is Either.Right ->
                when (result.value) {
                    is TechlaError.Unauthorized -> result.copy(value = Throwable("Permission denied. Talk to the Community Manager."))
                    else -> result
                }
        }

        return sceneOf(viewModel.failed(failure, store.isSignedIn))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return sceneOf<ViewModel>(viewModel.ready(), emptyList())
    }

    suspend fun handleSubmit(scene: Scene.Input<ViewModel>, code: String): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.verifyUserAuthentication(code = code).flatMap {
            val action = Store.Action.UserAuthenticationCompleted(tokens = it.tokens, profileId = it.profileId)
            store.reduce(action).me().accumulate(action)
        }.map { (actions, me) ->
            val action = Store.Action.GetMe(me = me)
            sceneOf<ViewModel>(viewModel.submitted(), actions + action)
        }.failed {
            scene.failed(result = it)
        }
    }
}