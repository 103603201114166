package techla.base

import kotlinx.serialization.Serializable

fun <A> tupleOf(a: A) =
    a

fun <A, B> tupleOf(a: A, b: B) =
    Pair(a, b)

fun <A, B, C> tupleOf(a: A, b: B, c: C) =
    Triple(a, b, c)

@Serializable
data class Quadruple<out A, out B, out C, out D>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
) {
    override fun toString(): String = "($first, $second, $third, $forth)"
}
fun <T> Quadruple<T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth)
fun <A, B, C, D> tupleOf(a: A, b: B, c: C, d: D) =
    Quadruple(a, b, c, d)

@Serializable
data class Quintuple<out A, out B, out C, out D, out E>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
    val fifth: E,
) {
    override fun toString(): String = "($first, $second, $third, $forth, $fifth)"
}
fun <T> Quintuple<T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth, fifth)
fun <A, B, C, D, E> tupleOf(a: A, b: B, c: C, d: D, e: E) =
    Quintuple(a, b, c, d, e)

@Serializable
data class Sextuple<out A, out B, out C, out D, out E, out F>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
    val fifth: E,
    val sixth: F,
) {
    override fun toString(): String = "($first, $second, $third, $forth, $fifth, $sixth)"
}
fun <T> Sextuple<T, T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth, fifth, sixth)
fun <A, B, C, D, E, F> tupleOf(a: A, b: B, c: C, d: D, e: E, f: F) =
    Sextuple(a, b, c, d, e, f)

@Serializable
data class Septuple<out A, out B, out C, out D, out E, out F, out G>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
    val fifth: E,
    val sixth: F,
    val seventh: G,
) {
    override fun toString(): String = "($first, $second, $third, $forth, $fifth, $sixth, $seventh)"
}
fun <T> Septuple<T, T, T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth, fifth, sixth, seventh)
fun <A, B, C, D, E, F, G> tupleOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G) =
    Septuple(a, b, c, d, e, f, g)

@Serializable
data class Octuple<out A, out B, out C, out D, out E, out F, out G, out H>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
    val fifth: E,
    val sixth: F,
    val seventh: G,
    val eighth: H,
) {
    override fun toString(): String = "($first, $second, $third, $forth, $fifth, $sixth, $seventh, $eighth)"
}
fun <T> Octuple<T, T, T, T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth, fifth, sixth, seventh, eighth)
fun <A, B, C, D, E, F, G, H> tupleOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H) =
    Octuple(a, b, c, d, e, f, g, h)

@Serializable
data class Nonuple<out A, out B, out C, out D, out E, out F, out G, out H, out I>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
    val fifth: E,
    val sixth: F,
    val seventh: G,
    val eighth: H,
    val ninth: I,
) {
    override fun toString(): String = "($first, $second, $third, $forth, $fifth, $sixth, $seventh, $eighth, $ninth)"
}
fun <T> Nonuple<T, T, T, T, T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth, fifth, sixth, seventh, eighth, ninth)
fun <A, B, C, D, E, F, G, H, I> tupleOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I) =
    Nonuple(a, b, c, d, e, f, g, h, i)

@Serializable
data class Decuple<out A, out B, out C, out D, out E, out F, out G, out H, out I, out J>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
    val fifth: E,
    val sixth: F,
    val seventh: G,
    val eighth: H,
    val ninth: I,
    val tenth: J,
) {
    override fun toString(): String = "($first, $second, $third, $forth, $fifth, $sixth, $seventh, $eighth, $ninth, $tenth)"
}
fun <T> Decuple<T, T, T, T, T, T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth, fifth, sixth, seventh, eighth, ninth, tenth)
fun <A, B, C, D, E, F, G, H, I, J> tupleOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I, j: J) =
    Decuple(a, b, c, d, e, f, g, h, i, j)

@Serializable
data class Undecuple<out A, out B, out C, out D, out E, out F, out G, out H, out I, out J, out K>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
    val fifth: E,
    val sixth: F,
    val seventh: G,
    val eighth: H,
    val ninth: I,
    val tenth: J,
    val eleventh: K,
) {
    override fun toString(): String = "($first, $second, $third, $forth, $fifth, $sixth, $seventh, $eighth, $ninth, $tenth, $eleventh)"
}
fun <T> Undecuple<T, T, T, T, T, T, T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth, fifth, sixth, seventh, eighth, ninth, tenth, eleventh)
fun <A, B, C, D, E, F, G, H, I, J, K> tupleOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I, j: J, k: K) =
    Undecuple(a, b, c, d, e, f, g, h, i, j, k)

@Serializable
data class Duodecuple<out A, out B, out C, out D, out E, out F, out G, out H, out I, out J, out K, out L>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
    val fifth: E,
    val sixth: F,
    val seventh: G,
    val eighth: H,
    val ninth: I,
    val tenth: J,
    val eleventh: K,
    val twelfth: L,
) {
    override fun toString(): String = "($first, $second, $third, $forth, $fifth, $sixth, $seventh, $eighth, $ninth, $tenth, $eleventh, $twelfth)"
}
fun <T> Duodecuple<T, T, T, T, T, T, T, T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth, fifth, sixth, seventh, eighth, ninth, tenth, eleventh, twelfth)
fun <A, B, C, D, E, F, G, H, I, J, K, L> tupleOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I, j: J, k: K, l: L) =
    Duodecuple(a, b, c, d, e, f, g, h, i, j, k, l)

@Serializable
data class Tredecuple<out A, out B, out C, out D, out E, out F, out G, out H, out I, out J, out K, out L, out M>(
    val first: A,
    val second: B,
    val third: C,
    val forth: D,
    val fifth: E,
    val sixth: F,
    val seventh: G,
    val eighth: H,
    val ninth: I,
    val tenth: J,
    val eleventh: K,
    val twelfth: L,
    val thirteenth: M,
) {
    override fun toString(): String = "($first, $second, $third, $forth, $fifth, $sixth, $seventh, $eighth, $ninth, $tenth, $eleventh, $twelfth, $thirteenth)"
}
fun <T> Tredecuple<T, T, T, T, T, T, T, T, T, T, T, T, T>.toList(): List<T> = listOf(first, second, third, forth, fifth, sixth, seventh, eighth, ninth, tenth, eleventh, twelfth, thirteenth)
fun <A, B, C, D, E, F, G, H, I, J, K, L, M> tupleOf(a: A, b: B, c: C, d: D, e: E, f: F, g: G, h: H, i: I, j: J, k: K, l: L, m: M) =
    Tredecuple(a, b, c, d, e, f, g, h, i, j, k, l, m)
