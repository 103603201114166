package support

import techla.base.Date
import techla.base.toISOString


val Date.toYearMonthDayFormat
    get(): String = run {
        val year = dateTime.year
        val month = "${dateTime.monthNumber}".padStart(2, '0')
        val day = "${dateTime.dayOfMonth}".padStart(2, '0')
        return "$year-$month-$day"
    }

val Date.toHourMinuteFormat: String
    get() = run {
        val hour = "${dateTime.hour}".padStart(2, '0')
        val minute = "${dateTime.minute}".padStart(2, '0')
        return "$hour:$minute"
    }

val Date.toOption: DesignSystem.Option
    get() = DesignSystem.Option(title = toHourMinuteFormat, value = toISOString())